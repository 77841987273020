import React from 'react';
import './styles/Message.css';

const Message = ({ isBot, text }) => {
    if (!text) return null;
    return (
        <div className={isBot ? 'message bot' : 'message user'}
        style={{ textAlign: 'left' }}
        >
            {text}
        </div>
    );
};

export default Message;
