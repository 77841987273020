import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const AuthContext = React.createContext({
  isLoggedIn: false,
  isLoginModalVisible: false,
  setIsLoginModalVisible: () => {},
  isSignupModalVisible: false,
  setIsSignupModalVisible: () => {},
  user: null,
  setUser: () => {},
  sideEffectFunction: () => {},
  setSideEffectFunction: () => {},
});

const AuthProvider = ({children}) => {
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isSignupModalVisible, setIsSignupModalVisible] = useState(false);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
  const [isVerificationCodeModalVisible, setIsVerificationCodeModalVisible] = useState(false);
  const [isPaywallModalVisible, setIsPaywallModalVisible] = useState(false);
  const [sideEffectFunction, setSideEffectFunction] = useState(()=>{});
  const [verifSeed, setVerifSeed] = useState((null));
  const [email, setEmail] = useState("");
  const [onVerifySuccess, setOnVerifySuccess] = useState(()=>{});
  const [errorMessage, setErrorMessage] = useState('');

  const [numInteracts, setNumInteracts] = useState(0);
  const [numFilterUses, setNumFilterUses] = useState(0);

  const allExpirableValues = useMemo(()=> ({
    "interact": { value: numInteracts, threshold: 3,  payThreshold: 3}, // all users get 1 free interact
    "filter": { value: numFilterUses, threshold: 5, payThreshold: 10 },
    // Add more { value, threshold } pairs if needed
}), [numInteracts, numFilterUses]);
  
  // const isPreSignupTrialExpired = useMemo(() => 
  //     allExpirableValues.some(pair => pair.value >= pair.threshold),
  //     // Flatten the array to get a list of values for the dependency array
  //     [allExpirableValues]
  // );

  const [user, setUser] = useState(null);

  const isPreSignupTrialExpired = useCallback((filter) => {
    const { value, threshold } = allExpirableValues[filter];
    return value >= threshold;
  }, [allExpirableValues])

  const isPrePayTrialExpired = useCallback((filter, localUser) => {
    const { value, payThreshold } = allExpirableValues[filter];
    return localUser?.subscriptionTier !== 'Basic Plan' && value >= payThreshold;
  }, [allExpirableValues])

  const getUser = useMemo(()=> {
    if (user) {
      return user;
    }
    // const userCookie = Cookies.get('user');
    let userCookie;
    if (userCookie === undefined) {
      return undefined;
    }
    return JSON.parse(userCookie);
  }, [user]);

  useEffect(()=> {
    setNumInteracts(Number(Cookies.get('numInteracts') || 0));
    setNumFilterUses(Number(Cookies.get('numFilterUses') || 0));
  }, []);

  const contextValue = useMemo(()=> ({
    isLoginModalVisible,
    setIsLoginModalVisible,
    isSignupModalVisible,
    setIsSignupModalVisible,
    isForgotPasswordModalVisible,
    setIsForgotPasswordModalVisible,
    isVerificationCodeModalVisible,
    setIsVerificationCodeModalVisible,
    isPaywallModalVisible,
    setIsPaywallModalVisible,
    verifSeed, setVerifSeed,
    email, setEmail,
    get user() {
      return getUser;
    },
    setUser: (u) => { 
      setUser(u); 
      // if (!u) {
      //   Cookies.remove('user');
      //   return;
      // }
      // Cookies.set('user', JSON.stringify(u));
    },
    sideEffectFunction,
    setSideEffectFunction,
    clearSideEffectFunction: ()=> { setSideEffectFunction(()=>{})},
    onVerifySuccess,
    setOnVerifySuccess,
    errorMessage,
    setErrorMessage,
    isPreSignupTrialExpired,
    isPrePayTrialExpired,
    setNumInteracts: (n) => {
      if (typeof n === 'function') {
          setNumInteracts(prev => {
              const newValue = n(prev);
              Cookies.set('numInteracts', newValue);
              return newValue;
          });
      } else {
          setNumInteracts(n);
          Cookies.set('numInteracts', n);
      }
    },
    setNumFilterUses: (n) => {
      if (typeof n === 'function') {
          setNumFilterUses(prev => {
              const newValue = n(prev);
              Cookies.set('numFilterUses', newValue);
              return newValue;
          });
      } else {
          setNumFilterUses(n);
          Cookies.set('numFilterUses', n);
      }
    },
    simpleUser: user,
  }), [
    isLoginModalVisible, setIsLoginModalVisible,
    isSignupModalVisible, setIsSignupModalVisible,
    isForgotPasswordModalVisible, setIsForgotPasswordModalVisible,
    isVerificationCodeModalVisible, setIsVerificationCodeModalVisible,
    isPaywallModalVisible, setIsPaywallModalVisible,
    verifSeed, setVerifSeed,
    email, setEmail,
    getUser, setUser,
    sideEffectFunction, setSideEffectFunction,
    errorMessage, setErrorMessage,
    onVerifySuccess, setOnVerifySuccess,
    isPreSignupTrialExpired,
    isPrePayTrialExpired,
    setNumFilterUses,
    setNumInteracts,
    user
  ])

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthContext, AuthProvider };
