import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export default function PrivacyPolicy() {

  const privacyPolicyContent = {
    effectiveDate: "Effective date: September 1, 2023",

    intro: `Welcome to Fanafic.com (“we,” “our,” or “us”).

    We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at contact@fanafic.com.
    `,
    informationCollected: `We collect personal information that you provide to us such as name, address, contact information, passwords, and security data, and payment information.

    We automatically collect certain information when you visit, use, or navigate the Sites. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser, and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Sites, and other technical information.
    `,
    useOfInformation: `We use personal information collected via our Sites for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.

    - To facilitate account creation and logon process.
    - To send you marketing and promotional communications.
    - To send administrative information to you.
    - To fulfill and manage your orders.
    - To post testimonials.
    - For other business purposes.
    `,
    sharingOfInformation: `We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.
    `,
    cookies: `We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
    `,
    socialLogins: `If you choose to register or log in to our websites using a social media account, we may have access to certain information about you.
    `,
    thirdPartyWebsites: `We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our websites.
    `,
    retentionOfInformation: `We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
    `,
    informationSafety: `We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process.
    `,
    collectionFromMinors: `We do not knowingly collect data from or market to children under 18 years of age.
    `,
    privacyRights: `You may review, change, or terminate your account at any time.
    `,
    policyUpdates: `Yes, we will update this policy as necessary to stay compliant with relevant laws.
    `,
    contact: `If you have questions or comments about this policy, you may email us at contact@fanafic.com.
    `
  };

  return (
    <Container maxWidth="md">
      <Stack
        sx={{
          maxWidth: 720,
          mx: 'auto',
          mt: { xs: 5, md: 10 },
          mb: 5
        }}
      >
        <Typography variant="h3" sx={{ mb: 5 }}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {privacyPolicyContent.effectiveDate}
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.intro}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          What Information Do We Collect?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.informationCollected}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          How Do We Use Your Information?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.useOfInformation}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Will Your Information Be Shared With Anyone?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.sharingOfInformation}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Do We Use Cookies And Other Tracking Technologies?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.cookies}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          How Do We Handle Your Social Logins?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.socialLogins}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          What Is Our Stance On Third-Party Websites?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.thirdPartyWebsites}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          How Long Do We Keep Your Information?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.retentionOfInformation}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          How Do We Keep Your Information Safe?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.informationSafety}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Do We Collect Information From Minors?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.collectionFromMinors}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          What Are Your Privacy Rights?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.privacyRights}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Do We Make Updates To This Policy?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.policyUpdates}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          How Can You Contact Us About This Policy?
        </Typography>
        <Typography variant="body1">
          {privacyPolicyContent.contact}
        </Typography>
      </Stack>
    </Container>
  );
}
