import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

const ContentContext = React.createContext();

// This context wrapper is a global state for unpersisted content across all pages
// If the user navigates away, for example to the payment page, the content is preserved for the next time they come back
// If the browser is refreshed, the content is lost
const ContentContextProvider = ({children}) => {
    const [content, setContent] = useState({
        // array so can store each fanverse's content separately
        // each fanverse has an array of messages, and also a string for the input
        // is it easier to hydrate the content from the server if it's an object? let's think:
        // - if it's an array, then we have to know the index of each fanverse
        // - if it's an object, then we can just use the fanverse name as the key
        // - but then we have to know the fanverse name
        // - so we can just store the fanverse name in the object too

        writePageContent: [],
        editPageContent: '',
    });

    const value = useMemo(() => ({content, setContent}), [content, setContent]);

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}

ContentContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { ContentContextProvider, ContentContext };
