import { CardElement } from '@stripe/react-stripe-js';
const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "white",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  

export default function CustomCardElement() {
    return (
        <CardElement
            options={CARD_ELEMENT_OPTIONS}
            // onChange={handleChange}
        />
    )
}