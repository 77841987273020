import PropTypes from 'prop-types';
import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// assets
import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from 'src/assets/icons';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
//
import { AddressListDialog } from 'src/sections/address';
import PaymentCardListDialog from 'src/components/Payment/payment-card-list-dialog';
import { AuthContext } from 'src/auth/AuthContext';
import CustomCardElement from 'src/components/Payment/CustomCardElement';
import { API_URL } from 'src/components/utilities';

// ----------------------------------------------------------------------

export default function AccountBillingPlan({ cardList, addressBook, plans }) {
  const { user, setUser } = useContext(AuthContext);
  const openAddress = useBoolean();

  const openCards = useBoolean();

  const primaryAddress = addressBook.filter((address) => address.primary)[0];

  const [selectedPlan, setSelectedPlan] = useState('');

  const [selectedAddress, setSelectedAddress] = useState(primaryAddress);

  const [selectedCard, setSelectedCard] = useState(null);
  const [open, setOpen] = useState(false);

    // TODO have this track primary instead of just getting first one
  // const primaryCard = cardList[0];
  useEffect(() => {
    if (!cardList || cardList.length === 0) {
      return;
    }
    // setSelectedCard(cardList.filter((card) => card.primary)[0]);
    setSelectedCard(cardList[0]);
  }, [cardList, setSelectedCard]);

  useEffect(() => {
    if (selectedPlan && selectedCard) {
      setOpen(false);
    }
  }, [selectedPlan, selectedCard])

  const handleSelectPlan = useCallback(
    (newValue) => {
      console.log('new value', newValue);
      const currentPlan = user?.subscriptionTier ?? 'Free';
      if (currentPlan !== newValue) {
        setSelectedPlan(newValue);
      }
    },
    [user]
  );

  const handleCancel = async () => {
    console.log('cancel');
    try {
      const params = new URLSearchParams();
      params.append('userId', user._id);
      params.append('subscriptionId', user.subscriptionId);
      const response = await fetch(`${API_URL}/api/payment/cancel-subscription`, {
          method: 'POST',
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          },
          body: params.toString(),
        // Pass any required data like planID, customerID etc.
      });

      const data = await response.json();
      const {user: updatedUser} = data;
      if (updatedUser) {
        setUser({...user, subscriptionTier: "Free"});
      }
    } catch (error) {
      console.log(error);
      // TODO handle error
      throw new Error(error);
    }
  }

  const handleSwitch = async () => {
    console.log('switch');
    if (selectedPlan === 'Free') {
      handleCancel();
    } else {
      // TODO handle switch
      console.log('switch to ', selectedPlan);
      if (getUpgradeSubscriptionError(selectedPlan, selectedCard)) {
        setOpen(true);
        return;
      }
      try {
        const [matchingPlan] = plans.filter((plan) => plan.subscription === selectedPlan);
        const { priceId } = matchingPlan || {};
        const params = new URLSearchParams();
        params.append('userId', user._id);
        params.append('subscriptionId', user.subscriptionId);
        params.append('priceId', priceId);
        params.append('paymentMethodId', selectedCard.id);
        params.append('customerId', user.customerId);
        const response = await fetch(`${API_URL}/api/payment/switch-subscription`, {
            method: 'POST',
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: params.toString(),
          // Pass any required data like planID, customerID etc.
        });
  
        const data = await response.json();
        const {user: updatedUser } = data;
        if (updatedUser) {
          setUser({...user, subscriptionTier: updatedUser?.subscriptionTier, subscriptionId: updatedUser?.subscriptionId });
        }
      } catch (error) {
        console.log(error);
        // TODO handle error
        throw new Error(error);
      }
    }
  }

  const handleSelectAddress = useCallback((newValue) => {
    setSelectedAddress(newValue);
  }, []);

  const handleSelectCard = useCallback((newValue) => {
    setSelectedCard(newValue);
  }, []);

  const getIsCurrentPlan = useCallback((plan) => 
  user?.subscriptionTier === plan, [user]);

  const renderPlans = plans.map((plan) => {
    const isCurrentPlan = getIsCurrentPlan(plan.subscription);
    return <Grid xs={12} md={4} key={plan.subscription}>
      <Stack
        component={Paper}
        variant="outlined"
        onClick={() => handleSelectPlan(plan.subscription)}
        sx={{
          p: 2.5,
          position: 'relative',
          cursor: 'pointer',
          ...(isCurrentPlan && {
            opacity: 0.48,
            cursor: 'default',
          }),
          ...(plan.subscription === selectedPlan && {
            boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
          }),
        }}
      >
        {isCurrentPlan && (
          <Label
            color="info"
            startIcon={<Iconify icon="eva:star-fill" />}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            Current
          </Label>
        )}

        <Box sx={{ width: 48, height: 48 }}>
          {plan.subscription === 'Free' && <PlanFreeIcon />}
          {plan.subscription === 'Basic Plan' && <PlanStarterIcon />}
          {plan.subscription === 'Premium Plan' && <PlanPremiumIcon />}
        </Box>

        <Box
          sx={{
            typography: 'subtitle2',
            mt: 2,
            mb: 0.5,
            textTransform: 'capitalize',
          }}
        >
          {plan.subscription}
        </Box>

        <Stack direction="row" alignItems="center" sx={{ typography: 'h4' }}>
          {plan.price || 'Free'}

          {!!plan.price && (
            <Box component="span" sx={{ typography: 'body2', color: 'text.disabled', ml: 0.5 }}>
              /mo
            </Box>
          )}
        </Stack>
      </Stack>
    </Grid>
  }
  );

  const customCardElement = useMemo(() => React.createElement(CustomCardElement), []);

  return (
    <>
      <Card>
        <CardHeader title="Plan" />

        <Grid container spacing={2} sx={{ p: 3 }}>
          {renderPlans}
        </Grid>

        <Stack spacing={2} sx={{ p: 3, pt: 0, typography: 'body2' }}>
          {/* <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Plan
            </Grid>
            <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
              {selectedPlan || '-'}
            </Grid>
          </Grid> */}

          {/* <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Billing name
            </Grid>
            <Grid xs={12} md={8}>
              <Button
                onClick={openAddress.onTrue}
                endIcon={<Iconify width={16} icon="eva:arrow-ios-downward-fill" />}
                sx={{ typography: 'subtitle2', p: 0, borderRadius: 0 }}
              >
                {selectedAddress?.name}
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Billing address
            </Grid>
            <Grid xs={12} md={8} sx={{ color: 'text.secondary' }}>
              {selectedAddress?.fullAddress}
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Billing phone number
            </Grid>
            <Grid xs={12} md={8} sx={{ color: 'text.secondary' }}>
              {selectedAddress?.phoneNumber}
            </Grid>
          </Grid>
          */}

          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Payment method
            </Grid>
            <Grid xs={12} md={8}>
              <Button
                onClick={openCards.onTrue}
                endIcon={<Iconify width={16} icon="eva:arrow-ios-downward-fill" />}
                sx={{ typography: 'subtitle2', p: 0, borderRadius: 0 }}
              >
                {selectedCard?.card?.last4}
              </Button>
            </Grid>
          </Grid>
          <Collapse in={open}>
              <Alert
          severity="error"
          sx={{ mb: 2 }}
        >
          {getUpgradeSubscriptionError(selectedPlan, selectedCard)}
        </Alert>
        </Collapse>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />


        <Stack spacing={1.5} direction="row" justifyContent="flex-end" sx={{ p: 3 }}>
          <Button variant="outlined" onClick={handleCancel}>Cancel Plan</Button>
          <Button variant="contained" onClick={handleSwitch}>Switch Plan</Button>
        </Stack>
      </Card>

      <PaymentCardListDialog
        list={cardList}
        open={openCards.value}
        onClose={openCards.onFalse}
        selected={(selectedId) => selectedCard?.id === selectedId}
        onSelect={handleSelectCard}
        customCardElement={customCardElement}
      />

      <AddressListDialog
        list={addressBook}
        open={openAddress.value}
        onClose={openAddress.onFalse}
        selected={(selectedId) => selectedAddress?.id === selectedId}
        onSelect={handleSelectAddress}
        action={
          <Button
            size="small"
            startIcon={<Iconify icon="mingcute:add-line" />}
            sx={{ alignSelf: 'flex-end' }}
          >
            New
          </Button>
        }
      />
    </>
  );
}

const getUpgradeSubscriptionError = (selectedPlan, selectedCard) => {

  if (!selectedPlan) {
    return "Please select a plan";
  }
  
  if (!selectedCard) {
    return "Please select a payment method";
  }

  return null;
}

AccountBillingPlan.propTypes = {
  addressBook: PropTypes.array,
  cardList: PropTypes.array,
  plans: PropTypes.array,
};
