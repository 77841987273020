import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import './EditPage.css';
import { Input, Button, Typography, Dropdown, Menu, Checkbox,  } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import TransformationBuilder from './Transformations';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import {saveFunction, postFunction, fetchPost} from '../utilities';
import {AuthContext} from '../../auth/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import WritePageStyles from '../Interact/styles/WritePageStylesheet';
import '../Read/ReadPage.css';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import StyledButton from '../../common/StyledButton';
import useWSS from '../../common/useWSS';

const validContentLength = (content2) => {
  return content2.trim().length > 20;
}

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');
  body {
    background-color: rgb(29, 27, 30);
    font-family: 'IBM Plex Mono', monospace;
  }
`;

const { TextArea } = Input;

const StyledTextArea2 = styled(TextArea)`
    background: rgb(255, 255, 255, 0.5);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-family: 'IBM Plex Mono', monospace;
    color: black;
    text-align: left;
    &::placeholder {
        color: rgb(233, 224, 228);
    }
`;

const StyledTextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  min-height: 50vh;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-height: 3.4rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const EditPage = () => {
  const [selectedText, setSelectedText] = useState('');
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);

  const { state: content } = useLocation();
  const [showPlaceholder2, setShowPlaceholder2] = useState(true);
  const [content2, setContent2] = useState(content || '');
  const [selectedOption, setSelectedOption] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);  
  const {
    isLoggedIn, setIsLoggedIn,
    isLoginModalVisible, setIsLoginModalVisible,
    isSignupModalVisible, setIsSignupModalVisible,
    user, setUser,
    sideEffectFunction, setSideEffectFunction
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSubmitted(false);
  };
  const handleSubmit = () => {
    setSubmitted(true);
    postFunction({content2, draftId, userId: user._id}).then(({_id})=> {
      // navigate(`/read?id=${_id}`);
      navigate('/profile')
    })

  }
  const [submitted, setSubmitted] = useState(false);
  const [draftId, setDraftId] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      // this will clear Timeout when component unmont like in willComponentUnmount
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);


  const handleOptionClick = (option) => {
    if (!selectedText.trim()) {
      // If no text has been selected, show an error alert
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
      setSelectedOption(option);
      console.log("option", option, selectedText)
      handleStream({
        "filterPrompt": {
            "filter": option
        },
        "userInteractionContent": selectedText
    }, inputRef)
    }
  };

  const handleSave = () => {
    setSaving(true);
    setSaveDisabled(true);
    console.log("about to save with content: ", content2);
    saveFunction(content2, draftId, user._id).then((post)=> {
      console.log("POST", post);
      setDraftId(post._id);
    }).catch(()=> {
      setSaveDisabled(false);
    }).finally(() => {
      setSaving(false);
      setSaveDisabled(false);
    });
  };


  const transformationBuilder = useMemo(()=> TransformationBuilder(), []);
  const [transformationLoading, setTransformationLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const applyTransformations = async () => {
    setTransformationLoading(true);
    const result = await transformationBuilder.applyTransformations(selectedText);
    const updatedContent = [
        content2.slice(0, selectionStart),
        result.answer.trim(),
        content2.slice(selectionEnd),
    ].join('');
    setContent2(updatedContent);
    setTransformationLoading(false);
};

  const disableTransformation = (content2) => {
    // console.log("this is transf ", transformationBuilder, transformationBuilder.isEmpty());
    return !validContentLength(content2.trim());
    //  || transformationBuilder.isEmpty();
  }

  useEffect(() => {    
    const url = new URL(window.location.href);

    // Retrieve the value of the "id" parameter from the URL
    const id = url.searchParams.get('id');
    if (id) {
      fetchPost(id).then((post)=> {
        setContent2(post.content2);
        if (post.draft) {
          setDraftId(post._id);
        }
      })
    } else {
    }

  }, [])

  const handleBeforeUnload = (e) => {
    if (content2.trim() !== '') {
      e.preventDefault();
      e.returnValue = '';
      showModal();
    }
  };
  
  const inputRef = useRef(null);
  const [typing, setTyping, typingMessage, setTypingMessage, handleStream] = useWSS({
    content: content2, 
    setContent: setContent2,
  });

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [content2]);

  useEffect(() => {
    setContent2(content);
  }, [content]);

  const chipOptions = [
    'describe', 'eloquent', 'brainstorm', 'slangy', 'mystical', 'epic',
    'romanticize', 'intensify', 'gritty', 'suspenseful', 'funny', 'adventurous',
    'medieval', 'victorian', 'noir', 'satirical', 'edgy', 'cryptic',
    'tragic', 'apocalyptic'
];

useEffect(() => {
  const textarea = inputRef.current;

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();
    const maxSize = content2.length;

    if (selectedText && document.activeElement === textarea) {
          // Determine if the selection was made from left to right or right to left
          const direction = textarea.selectionDirection;
      var selectionStart = textarea.selectionStart;
      var selectionEnd = textarea.selectionEnd;

      // Find the start of the word that is selected at the start
      let newStartLine = textarea.value.lastIndexOf(' ', selectionStart - 1) + 1;
      let newStartSpace = textarea.value.lastIndexOf('\n', selectionStart - 1) + 1;
      let newStart = Math.max(newStartLine, newStartSpace);
      // if (newStart < 0) newStart = 0;

      // Find the end of the word that is selected at the end
      let newEndLine = textarea.value.indexOf('\n', selectionEnd);
      let newEndSpace = textarea.value.indexOf(' ', selectionEnd);
      let newEnd = Math.min(newEndLine, newEndSpace);
      if (newEndLine < 0) {
        newEnd = newEndSpace;
      } else if (newEndSpace < 0) {
        newEnd = newEndLine;
      }
      if (newEnd < 0) newEnd = textarea.value.length;

      // Adjust the start and end of the selection based on the direction
      if (direction === 'backward') {
        textarea.setSelectionRange(newStart, newEnd, 'backward');
        console.log("backward");
      } else {
        textarea.setSelectionRange(newStart, newEnd, 'forward');
        console.log("forward");
      }
    }
  };

  document.addEventListener('selectionchange', handleSelectionChange);

  // Clean up the event listener when the component is unmounted
  return () => {
    document.removeEventListener('selectionchange', handleSelectionChange);
  };
}, []);
  
  return (
    <>
    {showAlert && (
      <Alert
        style={{ zIndex: 1000 }}
        variant="outlined"
        severity="error"
      >
        Please select a sentence or paragraph before clicking a chip.
      </Alert>
    )}
          <div className="background-light" >
          <StyledButtonContainer>
            {chipOptions.map(option => (
              <StyledButton key={option} onClick={() => handleOptionClick(option)}>
                {option}
              </StyledButton>
            ))}
          </StyledButtonContainer>
          <style>
        {`
          textarea::selection {
            background: #ffb7b7;
            color: white;
          }
        `}
      </style>
            <StyledTextAreaWrapper>
              <textarea
              style={{...{
                background: 'rgba(255, 255, 255, 0.5)',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                // overflow: 'auto',
                padding: '20px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '15px',
                fontFamily: 'IBM Plex Mono, monospace',
                color: 'black',
                textAlign: 'left',
                '&::placeholder': {
                  color: 'rgb(233, 224, 228)',
                },
                whiteSpace: 'pre-wrap',
                lineHeight: '2',
              }, ...WritePageStyles.textAreaStyle}}
                ref = {inputRef}
                placeholder={showPlaceholder2 ? "Write a few sentences here, choose a tool above, then click the transform button below..." : ""}
                value={content2}
                onChange={(e) => {
                  setContent2(e.target.value);
                }}
                onSelect={(e) => {
                  const { selectionStart, selectionEnd } = e.target;
                  const selectedText = e.target.value.substring(selectionStart, selectionEnd);
                  setSelectedText(selectedText);
                }}
                onFocus={() => setShowPlaceholder2(false)}
                onBlur={() => {
                  if (content2 === '') {
                    setShowPlaceholder2(true);
                  }
                }}
              />
            </StyledTextAreaWrapper>
            <div>
                <Tooltip title="Edit with AI. Select a button, highlight your words, then click Transform.">
                    <StyledButton 
                        noBorder 
                        transformButton
                        disabled={transformationLoading || disableTransformation(content2)}
                        style={{ marginBottom: '20px', color: 'rgb(102, 90, 111) !important' }}
                        onClick={async ()=> { applyTransformations() }}>
                        {transformationLoading ? (
                            <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row" alignItems="center">
                            <CircularProgress size={12} color="secondary"/>
                            <Typography variant="body2">Transforming...</Typography>
                            </Stack>
                        ):
                        'Transform'}
                    </StyledButton>
                </Tooltip>
              <StyledButton onClick={handleSave} disabled={saveDisabled}>
                Save
              </StyledButton>
              <StyledButton onClick={handleSubmit} disabled={submitted}>
                Post
              </StyledButton>
            </div>
          </div>
    </>
  );
}

export default EditPage;