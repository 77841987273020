// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  // {
  //   title: 'Read',
  //   icon: <Iconify icon="solar:notebook-minimalistic-bold-duotone" />,
  //   path: '/read',
  // },
  {
    title: 'Write',
    icon: <Iconify icon="solar:pen-new-square-bold-duotone" />,
    path: '/write',
  },
];
