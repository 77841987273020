import React, { useEffect, useState, useContext } from 'react';
import { Card, Avatar, Typography, Row, Col, Button, Grid } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ColorButton from '../../common/ColorButton';
import OutlineButton from '../../common/OutlineButton';
import { fetchDrafts, fetchPosts } from '../utilities';
import { useLocation } from 'react-router-dom';
import {AuthContext} from '../../auth/AuthContext';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './ProfilePage.css';
import { UserOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';

const { Meta } = Card;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const ProfilePage = () => {
  const [draftData, setDraftData] = useState([]);
  const [postData, setPostData] = useState([]);
  const { user } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("base url", window.location.origin);
    console.log("user", user);
    console.log("fetching")
    if (user) {
      fetchPosts(user._id).then((results) => {
        setDraftData(results.filter(k => k.draft));
        setPostData(results.filter(k => !k.draft));
      });
    } else {
      navigate('/');
    }

  }, [user]);

  const breakpoints = useBreakpoint();

  const getSpanValue = () => {
    if (breakpoints.xs) {
      return 24;
    } else {
      return 8;
    }
  };

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '30px', width: '50%' }}
      >
        <Avatar size={128} icon={<UserOutlined />} />
        <Title level={2} style={{ margin: '10px 0' }}>
          {user?.firstName} {user?.lastName}
        </Title>
        <Text type="secondary">@{user?.username}</Text>
  
        <Box sx={{ width: '100%', marginTop: '30px' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            centered
            sx={{marginBottom: '2rem'}}
          >
            <Tab label="Drafts" />
            <Tab label="Posts" />
          </Tabs>
          {value === 0 && (
            <div>
              <Row gutter={[90, 90]} justify={draftData.length === 0 ? 'center' : 'center'}>
                <Col>
                  <Link to="/write">
                    <Card
                      bordered={false}
                      className="profile-card"
                      cover={
                        <div className="card-content">
                          <PlusOutlined className="card-icon" />
                        </div>
                      }
                    ></Card>
                  </Link>
                </Col>
                {draftData.map(({ _id, content }) => (
                  <Col key={_id}>
                    <Link to={`/write?id=${_id}`}>
                      <Card
                        bordered={false}
                        className="profile-card"
                        cover={
                          <div className="card-content">
                            <p>{(content || '').split(' ').slice(0, 20).join(' ') + '...'}</p>
                          </div>
                        }
                      ></Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {value === 1 && (
            <div>
              <Row gutter={[90, 90]} justify={postData.length === 0 ? 'center' : 'center'}>
                <Col>
                  <Link to="/write">
                    <Card
                      bordered={false}
                      className="profile-card"
                      cover={
                        <div className="card-content">
                          <PlusOutlined className="card-icon" />
                        </div>
                      }
                    ></Card>
                  </Link>
                </Col>
                {postData.map(({ _id, content }) => (
                  <Col key={_id}>
                    <Link to={`/read?id=${_id}`}>
                      <Card
                        bordered={false}
                        className="profile-card"
                        cover={
                          <div className="card-content">
                            <p>{(content || '').split(' ').slice(0, 20).join(' ') + '...'}</p>
                          </div>
                        }
                      ></Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default ProfilePage;
