import PropTypes from 'prop-types';
import { useState, useCallback, useContext, useEffect } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// stripe
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// components
import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';
//
import PaymentCardItem from './payment-card-item';
import { AuthContext } from 'src/auth/AuthContext';
import { API_URL } from '../utilities';
// ----------------------------------------------------------------------
export default function PaymentCardListDialog({ open, list, onClose, selected, onSelect, customCardElement }) {
  const [searchCard, setSearchCard] = useState('');
  const [showNewCard, setShowNewCard] = useState(false);
  const [cardList, setCardList] = useState(list || []);

  useEffect(() => {
    console.log('is open: ', open);
  }, [open])

  useEffect(() => {
    setCardList(list);
  }, [list]);
  const stripe = useStripe();
  const elements = useElements();
  let cardElement;
  if (!elements) {
    console.log('WARNING: Elements is null. Wont be able to create new card element.');
  } else {
    console.log('INFO: elements ok now')
    cardElement = elements.getElement(CardElement);
  }
  const { user, setUser } = useContext(AuthContext);

  const dataFiltered = applyFilter({
    inputData: cardList,
    query: searchCard,
  });

  const notFound = !dataFiltered?.length && !!searchCard;

  const handleSearchAddress = useCallback((event) => {
    setSearchCard(event.target.value);
  }, []);

  const handleSelectCard = useCallback(
    (card) => {
      onSelect(card);
      setSearchCard('');
      onClose();
    },
    [onClose, onSelect]
  );

  const renderList = (
    <Stack spacing={2.5} sx={{ p: 3 }}>
      {cardList.map((card) => (
        <PaymentCardItem
          key={card.id}
          card={card}
          cardList={cardList}
          setCardList={setCardList}
          onClick={() => handleSelectCard(card)}
          sx={{
            cursor: 'pointer',
            ...(selected(card.id) && {
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
            }),
          }}
        />
      ))}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => {
      onClose();
      setShowNewCard(false);
    }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6"> Cards </Typography>

      {showNewCard ? renderSubmitCardButton(cardElement, stripe, user, setUser, cardList, setCardList, setShowNewCard) : renderAddCardButton(setShowNewCard)}
      </Stack>

      <Stack sx={{ px: 3, py: 4, display: showNewCard ? 'block' : 'none' }}>
        <Stack spacing={1}>
          {customCardElement}
        </Stack>
      </Stack>
      <Stack sx={{ px: 3 }}>
        <TextField
          value={searchCard}
          onChange={handleSearchAddress}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {notFound ? <SearchNotFound query={searchCard} sx={{ px: 3, pt: 5, pb: 10 }} /> : renderList}
    </Dialog>
  );
}

PaymentCardListDialog.propTypes = {
  list: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.func,
};

// ----------------------------------------------------------------------

function applyFilter({ inputData, query }) {
  if (query) {
    return inputData.filter(
      (card) => card.cardNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return inputData;
}

function renderAddCardButton(setShowNewCard) {
    return (
      <Button
        size="small"
        startIcon={<Iconify icon="mingcute:add-line" />}
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => setShowNewCard(true)}
      >
        New Card
      </Button>
    )
}

function renderSubmitCardButton(cardElement, stripe, user, setUser, cardList, setCardList, setShowNewCard) {
  const createElement = async () => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      // setErrorMessage(error.message);
      return;
    }
    // Get client secret from your backend
    let data;
    try {
      const params = new URLSearchParams();
      params.append('email', user.email);
      params.append('paymentMethodId', paymentMethod.id);
      params.append('customerId', user.customerId);
      params.append('userId', user._id);
      const response = await fetch(`${API_URL}/api/payment/attach-payment-method`, {
          method: 'POST',
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          },
          body: params.toString(),
        // Pass any required data like planID, customerID etc.
      });

      data = await response.json();
      if (data?.paymentMethod) {
        console.log('setting new card');
        data.paymentMethod.new = true;
        setCardList([data?.paymentMethod, ...cardList]);
        setShowNewCard(false);
        setUser({
          ...user,
          customerId: data?.paymentMethod?.customer,
        })
      }
    } catch (error) {
      console.error('There was an error!', error);
      // setErrorMessage(error.message);
    }
    
}
  return (
    <Button
      size="small"
      startIcon={<Iconify icon="mingcute:add-line" />}
      sx={{ alignSelf: 'flex-end' }}
      variant='contained'
      color={'primary'}
      onClick={createElement}
    >
      Done
    </Button>
  )
}