import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Input, Link, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {AuthContext} from '../AuthContext';
import API_URL_BASE from '../../api/API_URL';

const generateOTP = require('@vingaogaogao/otp-generator');

const API_URL = `${API_URL_BASE}/forgotPassword/checkVerificationCode`;

const Verification = ({handleAttemptToClose: close, handleChangeModal: move}) => {
    const {
        isVerificationCodeModalVisible, setIsVerificationCodeModalVisible,
        setUser,
        verifSeed,
        email,
        onVerifySuccess,
        errorMessage, setErrorMessage,
      } = useContext(AuthContext);

    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAttemptToClose = () => {
        close();
        setErrorMessage(null);
    }

    const handleChangeModal = (type) => {
        move(type);
        setErrorMessage(null);
    }

    const getVerifiedUser = useCallback(async (veriCode, veriEmail) => {
        const formData = new URLSearchParams();
        formData.append('code', veriCode);
        formData.append('email', veriEmail);
        if (loading) return; // prevent useEffect loop
        setLoading(true);
        try {
          const response = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(), // Convert the URLSearchParams object to a string
          });

          if (response.ok) {
            const result = await response.json();
            if (onVerifySuccess) {
              const successFunc = await onVerifySuccess; // do error validation here?
              const postFuncResult = await successFunc();
              if (!postFuncResult) {
                return;
              }
              setUser(postFuncResult.user);
              setIsVerificationCodeModalVisible(false);
              return;
            }
            setUser(result.user);
            setIsVerificationCodeModalVisible(false);
          }
        
          // Handle the response as needed
        } catch (error) {
          console.error('An error occurred:', error);
        } finally {
          setLoading(false);
        }
    }, [setUser, setIsVerificationCodeModalVisible, onVerifySuccess, loading]);

      useEffect(() => {
        if (code === generateOTP(verifSeed)) {
            
            getVerifiedUser(code, email)
        }
      }, [code, verifSeed, email, getVerifiedUser])

      return (
        <Dialog
            open={isVerificationCodeModalVisible}
            onClose={handleAttemptToClose}
        >
            <DialogTitle>Enter Verification Code</DialogTitle>
            <DialogContent>
                <Input
                    placeholder="Code"
                    style={{ marginBottom: '10px', width: '100%' }}
                    value={code}
                    onChange={e => setCode(e.target.value)}
                />
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
                    </div>
                )}
                {errorMessage && (
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                )}
                <div style={{ marginTop: '10px' }}>
                  <Link variant="body2" color="primary" component="p" onClick={() => {
                    handleChangeModal("login");
                  }}>
                    Back to Login
                  </Link>
                </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAttemptToClose} color="primary" variant="soft">
                Cancel
              </Button>
            </DialogActions>
        </Dialog>
    )
}

// Write all proptypes for Verification here
Verification.propTypes = {
    handleAttemptToClose: PropTypes.func,
    handleChangeModal: PropTypes.func,
}

export default Verification;