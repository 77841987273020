import { m } from 'framer-motion';
import { Link } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// components
import { MotionViewport, varFade } from 'src/components/animate';

// ----------------------------------------------------------------------

const CARDS = [
  {
    icon: ' /assets/icons/home/ic_make_brand.svg',
    title: 'Chat with Characters',
    description: 'Hold conversations with your favorite characters. Join their journeys. Alter their destinies!',
  },
  {
    icon: ' /assets/icons/home/ic_design.svg',
    title: 'Edit with AI',
    description:
      'Our AI writing tools can transform your words, adhering to the style and lore of the original universe.',
  },
  {
    icon: ' /assets/icons/home/ic_development.svg',
    title: 'Share with Friends (beta)',
    description: 'Invite friends to collaborate on your stories. Showcase your masterpieces to the world.',
  },
];

// ----------------------------------------------------------------------

export default function HomeMinimal() {
  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 10, md: 15 },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          textAlign: 'center',
          mb: { xs: 5, md: 10 },
        }}
      >
        <m.div variants={varFade().inUp}>
          <Typography component="div" variant="overline" sx={{ color: 'text.disabled' }}>
            Fan&fic
          </Typography>
        </m.div>

        <m.div variants={varFade().inDown}>
          <Typography variant="h2">
            How does FAN&FIC work?
          </Typography>
        </m.div>
      </Stack>

      <Box
        gap={{ xs: 3, lg: 10 }}
        display="grid"
        alignItems="center"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {CARDS.map((card, index) => (
          <m.div variants={varFade().inUp} key={card.title}>
            <Link 
              to={card.title === 'Chat with Characters' ? '/write' : 
                  card.title === 'Edit with AI' ? '/editor' : '#'}
              style={{ textDecoration: 'none' }} 
            >
              <Card
                sx={{
                  textAlign: 'center',
                  boxShadow: (theme) => ({
                      md: `-40px 40px 80px ${
                          theme.palette.mode === 'light'
                              ? alpha(theme.palette.grey[500], 0.16)
                              : alpha(theme.palette.common.black, 0.4)
                      }`,
                  }),
                  bgcolor: 'background.default',
                  p: (theme) => theme.spacing(10, 5),
                }}
              >
                <Box
                  component="img"
                  src={card.icon}
                  alt={card.title}
                  sx={{ mx: 'auto', width: 48, height: 48 }}
                />

                <Typography variant="h5" sx={{ mt: 8, mb: 2 }}>
                  {card.title}
                </Typography>

                <Typography sx={{ color: 'text.secondary' }}>{card.description}</Typography>
              </Card>
            </Link>
          </m.div>
        ))}
      </Box>
    </Container>
  );
}
