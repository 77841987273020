import React, { useState, useContext } from 'react';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { AuthContext } from '../AuthContext';
import API_URL from '../../api/API_URL';

const LogIn = ({handleAttemptToClose, handleModalVisibility, handleChangeModal }) => {
    const {
        isLoginModalVisible,
        setUser,
        sideEffectFunction,
        clearSideEffectFunction
      } = useContext(AuthContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleLoginSubmit = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `logUsername=${username}&logPassword=${password}&isMobile=true`,
          });
      
          console.log('Got response:', response); 
      
          const data = await response.json();
      
          console.log('Response data:', data);
      
          if (data?.success) {
            handleModalVisibility('login', false);
            setUser(data.user);
            sideEffectFunction();
            clearSideEffectFunction();
          } else {
            setErrorMessage("Wrong username and/or password. Please try again.");
          }
        } catch (error) {
          console.error(error);
          setErrorMessage('An error occurred. Please try again.');
        } finally {
          setLoading(false);
        }
      }

      return (
        <Dialog
          open={isLoginModalVisible}
          onClose={handleAttemptToClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Log In</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="username"
              label="Username"
              type="text"
              fullWidth
              variant="outlined"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
              </div>
            )}
            <Stack spacing={1} sx={{ mt: 1 }}>
              {errorMessage && (
                <Alert severity="error">
                  {errorMessage}
                  </Alert>
              )}
              <Link variant="body2" color="inherit" component="p" onClick={() => {
                handleChangeModal("forgot");
              }}>
                Forgot password?
              </Link>
              <Link variant="body2" color="primary" component="p" onClick={() => {
                handleModalVisibility('login', false);
                handleModalVisibility('signup', true);
              }}>
                Don&apos;t have an account? Sign up
              </Link>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAttemptToClose} color="primary" variant="soft">
              Cancel
            </Button>
            <Button onClick={handleLoginSubmit} color="primary" variant="contained">
              Log In
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    
    LogIn.propTypes = {
      handleAttemptToClose: PropTypes.func,
      handleChangeModal: PropTypes.func,
      handleModalVisibility: PropTypes.func,
    }
    
    export default LogIn;