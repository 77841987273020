import API_URL from "src/api/API_URL";

const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

export default function TransformationBuilder() {
    // Private variables
    var transformationConfig = {}
  
    const chipOptions = [
      'describe', 'eloquent', 'brainstorm', 'slangy', 'mystical', 'epic',
      'romanticize', 'intensify', 'gritty', 'suspenseful', 'funny', 'adventurous',
      'medieval', 'victorian', 'noir', 'satirical', 'edgy', 'cryptic',
      'tragic', 'apocalyptic'
    ];
  
    const transformationMethods = {};
  
    chipOptions.forEach(option => {
      const methodKey = `add${option.charAt(0).toUpperCase() + option.slice(1)}`;
      transformationMethods[methodKey] = function () {
        transformationConfig = {};
        transformationConfig[option.toUpperCase()] = true;
        console.log(`add ${option}`);
        return this;
      };
    });
  
    // Public methods
    return {
      config: transformationConfig,
      ...transformationMethods,
      applyTransformations: function (content) {
        console.log("transformationConfig", transformationConfig)
        return new Promise(async (res, rej) => {
          const result = await fetchTransformation(transformationConfig, content);
          console.log("MY RESULT", result);
          res(result);
        });
      },
      clearTransformations: function () {
        transformationConfig = {};
        return this;
      }
    };
  }
  
async function fetchTransformation (config, content) {
    const data = {
        config: config,
        content: content
    }
    console.log("fetching transformation");
    const response = await fetch(`${API_URL}/api/gpt`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        });
    return await response.json();
}