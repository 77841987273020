import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

export default function TermsAndConditions() {

  const termsContent = {
    effectiveDate: "Effective date: September 1, 2023",

    intro: `Welcome to fanafic.com!

    These terms and conditions outline the rules and regulations for the use of fanafic.com's application, located at fanafic.com.

    By accessing this application or website, we assume you accept these terms and conditions. Do not continue to use fanafic.com if you do not agree to take all of the terms and conditions stated on this page.

    The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice, and all Agreements: “Client,” “You,” and “Your” refers to you, the person logging on to this application or website and compliant with the Company’s terms and conditions. “The Company,” “Ourselves,” “We,” “Our,” and “Us,” refers to our Company, fanafic.com. “Party,” “Parties,” or “Us,” refers to both the Client and ourselves.
    `,
    license: `Unless otherwise stated, fanafic.com and/or its licensors own the intellectual property rights for all material on fanafic.com. All intellectual property rights are reserved. You may access this from fanafic.com for your own personal use subjected to restrictions set in these terms and conditions.

    You must not:

    Republish material from fanafic.com
    Sell, rent, or sub-license material from fanafic.com
    Reproduce, duplicate, or copy material from fanafic.com
    Redistribute content from fanafic.com
    This Agreement shall begin on the date hereof.
    `,
    content: `Users are allowed to post their content, such as text, images, and other material (“Content”), on fanafic.com. By posting Content, you grant fanafic.com a non-exclusive, worldwide, irrevocable, royalty-free license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.

    Your Content must be your own and must not infringe on the rights of any third parties. fanafic.com reserves the right to remove any of your Content from this application or website at any time, without notice.
    `,
    userConduct: `Users are expected to conduct themselves respectfully and lawfully while using fanafic.com. The following activities are strictly prohibited:

    Posting or sharing any content that is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable;
    Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity;
    Transmitting, sending, or otherwise making available any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;
    Transmitting, sending, or otherwise making available any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.
    `,
    privacyPolicy: `Please refer to our Privacy Policy for information on how we collect, use, and disclose information from our users.
    `,
    disclaimer: `To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our application, website, and the use of this application or website. Nothing in this disclaimer will:

    limit or exclude our or your liability for death or personal injury;
    limit or exclude our or your liability for fraud or fraudulent misrepresentation;
    limit any of our or your liabilities in any way that is not permitted under applicable law; or
    exclude any of our or your liabilities that may not be excluded under applicable law.
    The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.
    `,
    governingLaw: `These Terms shall be governed and construed in accordance with the laws of [Your Location], without regard to its conflict of law provisions.

    Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
    `,
    changes: `We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our discretion.

    By continuing to access or use our application or website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the application or website.
    `,
    contactUs: `If you have any questions about these Terms, please contact us at contact@fanafic.com.

    KYG Enterprises Inc

    Thank you for using fanafic.com!
    `
  };

  return (
    <Container maxWidth="md">
      <Stack
        sx={{
          maxWidth: 720,
          mx: 'auto',
          mt: { xs: 5, md: 10 },
          mb: 5
        }}
      >
        <Typography variant="h3" sx={{ mb: 5 }}>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {termsContent.effectiveDate}
        </Typography>
        <Typography variant="body1">
          {termsContent.intro}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          License
        </Typography>
        <Typography variant="body1">
          {termsContent.license}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Content
        </Typography>
        <Typography variant="body1">
          {termsContent.content}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          User Conduct
        </Typography>
        <Typography variant="body1">
          {termsContent.userConduct}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Privacy Policy
        </Typography>
        <Typography variant="body1">
          {termsContent.privacyPolicy}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Disclaimer
        </Typography>
        <Typography variant="body1">
          {termsContent.disclaimer}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Governing Law
        </Typography>
        <Typography variant="body1">
          {termsContent.governingLaw}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Changes
        </Typography>
        <Typography variant="body1">
          {termsContent.changes}
        </Typography>
        <Typography variant="h5" sx={{ mt: 5, mb: 5 }}>
          Contact Us
        </Typography>
        <Typography variant="body1">
          {termsContent.contactUs}
        </Typography>
      </Stack>
    </Container>
  );
}
