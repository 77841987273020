import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { AuthContext } from '../AuthContext';
import API_URL from '../../api/API_URL';
import useEmailOTP from '../hooks/useEmailOTP';

const SignUp = ({ handleAttemptToClose, handleModalVisibility, handleChangeModal }) => {
  const { isSignupModalVisible } = useContext(AuthContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupUsername, setSignupUsername] = useState('');
  const [email, setEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    setVerifSeed,
    setEmail: setAuthEmail,
    errorMessage: signupErrorMessage,
    setErrorMessage: setSignupErrorMessage,
} = useContext(AuthContext);
  const { verifLoading, failureMessage, handleEmailOTP } = useEmailOTP(setVerifSeed);

  const checkUserExisting = useCallback(async (localUsername, localEmail) => {
    try {
      const response = await fetch(`${API_URL}/api/users/exists?email=${localEmail}&username=${localUsername}`, {
        method: 'GET', // Adjust the HTTP method as needed
        headers: {
          'Content-Type': 'application/json', // Adjust headers as needed
          // Add other headers if required
        },
      });

      const userBody = await response.json();
      if (userBody?.exists) {
        if (localUsername === userBody?.username) {
          setSignupErrorMessage('Username already exists. Please try again.');
        } else if (localEmail === userBody?.email) {
          setSignupErrorMessage('Email already exists. Please try again.');
        } else {
          setSignupErrorMessage('User already exists. Please try again.');
        }
        return true;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
    return null;
  }, [setSignupErrorMessage]);

  const handleSignupSubmit = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `firstName=${firstName}&lastName=${lastName}&username=${signupUsername}&email=${email}&password=${signupPassword}&isMobile=true`,
      });

      const data = await response.json();

      if (data?.success) {
        handleModalVisibility('signup', false);
        return {success: true, user: data?.user};
      }
        setSignupErrorMessage(data?.error || "Sign up failed. Please try again.");
    } catch (error) {
      console.error(error);
      setSignupErrorMessage(`An error occurred: ${error.message}. Please try again.`);
      return null;
    }
    return null;
  }, [firstName, lastName, signupUsername, email, signupPassword, handleModalVisibility, setSignupErrorMessage]);
  
  const handleSubmit = useCallback(async () => {
    setAuthEmail(email);
    setLoading(true);
    const exists = await checkUserExisting(signupUsername, email);
    setLoading(false);
    if (exists) {
      return null;
    }
    const response = await handleEmailOTP(email);
    if (response?.status === 'Email sent') {
        setTimeout(() => {
          handleChangeModal('verify', handleSignupSubmit);
        }, 500);
    }
    return null;
}, [email,
    // these are just to get rid of eslint errors, I don't expect them to change
    handleChangeModal, setAuthEmail, handleEmailOTP, handleSignupSubmit, checkUserExisting, signupUsername]);


  return (
    <Dialog
      open={isSignupModalVisible}
      onClose={handleAttemptToClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
            width: '500px', 
        },
      }}
    >
      <DialogTitle id="form-dialog-title">Sign Up</DialogTitle>
      <DialogContent>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          margin="dense"
          id="first-name"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="last-name"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
      </Stack>
        <TextField
          margin="dense"
          id="username"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          value={signupUsername}
          onChange={e => setSignupUsername(e.target.value)}
        />
        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          id="password"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={signupPassword}
          onChange={e => setSignupPassword(e.target.value)}
        />
        {verifLoading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
          </div>
        )}
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
          </div>
        )}
        {failureMessage && (
            <Alert severity="error">
                {failureMessage}
            </Alert>
        )}
        <Stack spacing={1} sx={{ mt: 1 }}>
        <Typography
          component="div"
          sx={{
            color: 'text.secondary',
            typography: 'caption',
            textAlign: 'left',
          }}
        >
          {'By signing up, I agree to '}
          <Link underline="always" color="text.primary">
            Terms of Service
          </Link>
          {' and '}
          <Link underline="always" color="text.primary">
            Privacy Policy
          </Link>
          .
        </Typography>
          {signupErrorMessage && (
            <Alert severity="error">
              {signupErrorMessage}
            </Alert>
          )}
          <Link variant="body2" color="primary" component="p" onClick={() => {
            handleChangeModal("login");
          }}>
            Already have an account? Login
          </Link>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAttemptToClose} color="primary" variant="soft">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Sign Up
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SignUp.propTypes = {
  handleAttemptToClose: PropTypes.func,
  handleChangeModal: PropTypes.func,
  handleModalVisibility: PropTypes.func,
}

export default SignUp;
