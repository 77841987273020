import React, { createContext, useState } from 'react';

// Create a new context
const FanverseContext = createContext();

// Create a provider component
const FanverseProvider = ({ children }) => {
  // Define the state or any data you want to share in the context
  const [state, setState] = useState({
    data: [],
    // Add more properties as needed
  });
  const [fanverseIndex, setFanverseIndex] = useState(0);

  // Provide the state and functions through the context
  return (
    <FanverseContext.Provider
      value={{
        fanverseIndex,
        setFanverseIndex,
        // Add more functions or data properties to provide them through context
      }}
    >
      {children}
    </FanverseContext.Provider>
  );
};

export { FanverseContext, FanverseProvider };
