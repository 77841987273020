import React, { useState, useMemo, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/Home';
import ReadPage from './components/Read/ReadPage';
import EditPage from './components/Polish/EditPage';
import WritePage from './components/Interact/WritePage';
import ProfilePage from './components/Profile/ProfilePage';
import PostListPage from '../src/pages/dashboard/post/list';
import Header from './Header/Header';
import Footer from './components/Footer';
import {PaymentPage, Success} from './components/Payment';
import { AuthProvider } from './auth/AuthContext'
import AuthModal from './auth/AuthModal';
import './App.css';
import { ContentContextProvider } from './ContentContext';
import MotionLazy from 'src/components/animate/motion-lazy';
import EditorPage from './components/Polish/FanEdit';
import MainLayout from 'src/layouts/main';
import HomeView from 'src/sections/home/view/home-view.js';
import Account from 'src/pages/prefs/view/account';
import TermsAndConditions from 'src/sections/blog/view/terms-and-conditions';
import PrivacyPolicy from 'src/sections/blog/view/privacy-policy';
import ThemeProvider from 'src/theme';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { Helmet } from 'react-helmet-async';
import { SplashScreen } from 'src/components/loading-screen';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(STRIPE_KEY);

const HomeWithAuth = () => {
  return <>
    <MotionLazy>
      <MainLayout>
        <Helmet>
          <title> FAN&FIC: The starting point for your next fanfic</title>
        </Helmet>
        <HomeView />
      </MainLayout>
    </MotionLazy>
  </>
}

function App() {
  const [content, setContent] = useState('');

  const WriteWithAuth = () => {
    return <>
      <MainLayout>
        <WritePage />
      </MainLayout>
    </>
  }

  const EditWithAuth = () => {
    return <>
      <EditPage content={content} setContentParent={setContent} />
    </>
  }

  const FanEdit = () => 
    <MainLayout>
      <EditorPage />
    </MainLayout>

  const TermsAndConditionsPage = () => 
    <MainLayout>
      <TermsAndConditions />
    </MainLayout>

  const PrivacyPolicyPage = () => 
    <MainLayout>
      <PrivacyPolicy />
    </MainLayout>

  const ProfileWithAuth = () => <>
    <MainLayout>
      <PostListPage />
    </MainLayout>
  </>

  const Payment = () => <>
  
    <MainLayout>
      <Elements stripe={stripePromise} >
      <PaymentPage />
      </Elements>
    </MainLayout>
  </>

  const SuccessPage = () => <>
    <MainLayout>
      <Success />
    </MainLayout>
  </>

  const AccountPage = () => <>
    <MainLayout>
    <Elements stripe={stripePromise} >
      <Account />
      </Elements>
    </MainLayout>
  </>

  const AboutPage = lazy(() => import('src/pages/about-us'));

  return (
    <SettingsProvider
    defaultSettings={{
      themeMode: 'dark', // 'light' | 'dark'
      themeDirection: 'ltr', //  'rtl' | 'ltr'
      themeContrast: 'default', // 'default' | 'bold'
      themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
      themeColorPresets: 'purple', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
      themeStretch: false,
    }}
  >
    <ThemeProvider>
    <ContentContextProvider value={{ content, setContent }}>
        <AuthProvider>
        <Router>
          <AuthModal cancellable />
          {/* <div className={`App ${window.location.pathname === '/profile' ? 'ProfilePage' : ''}`}> */}
            <Suspense fallback={<SplashScreen />}>
              <Routes>
                <Route path="/" element={<HomeWithAuth />} />
                <Route path="/read" element={<ReadPage />} />
                <Route path="/edit" element={<EditWithAuth />} />
                <Route path="/write" element={<WriteWithAuth />} />
                <Route path="/profile" element={<ProfileWithAuth />} />
                <Route path="/editor" element={<FanEdit />} />
                <Route path="/terms&conditions" element={<TermsAndConditionsPage />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />
                <Route path="/about-us" element={
                  <MotionLazy>
                    <MainLayout>
                      <AboutPage />
                    </MainLayout>
                  </MotionLazy>
                } />
                <Route path="/payment" element={<Payment />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/account" element={<AccountPage />} />
              </Routes>
            </Suspense>
          {/* </div> */}
        </Router>
        </AuthProvider>
    </ContentContextProvider>
    </ThemeProvider>
    </SettingsProvider>
  );
}

export default App;
