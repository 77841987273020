import API_URL from "src/api/API_URL";

const postFunction = async (postParams) => {
    const {content, title, tagline, draftId, userId} = postParams;
    const data = new URLSearchParams();
    if (content) {
        data.append('content', content);
    }
    if (title) {
        data.append('title', title);
    }
    if (tagline) {
        data.append('tagline', tagline);
    }
    if (draftId) {
        data.append('draftId', draftId);
    }
    data.append('userId', userId);
    const response = await fetch(`${API_URL}/api/posts`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: data.toString(),
        });
    return await response.json();
};

const saveFunction = async (content, draftId, userId) => {
    console.log('saveFunction params', content, draftId);
    const data = new URLSearchParams();
    data.append('content', content);
    if (draftId) {
        data.append('draftId', draftId);
    }
    data.append('userId', userId);
    const response = await fetch(`${API_URL}/api/posts/saveDraft`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: data.toString(),
        });
    console.log('response', response);
    return await response.json();
}

const fetchPost = async (id) => {
    const response = await fetch(`${API_URL}/api/posts/${id}`);
    console.log('response', response);
    return await response.json();
}
const fetchDrafts = async (userId) => {
    const data = await fetch(`${API_URL}/api/posts/byUser/${userId}`);
    const results = await data.json();
    return results;
  };

const fetchPosts = async (userId) => {
const data = await fetch(`${API_URL}/api/posts/byUser/${userId}`);
const results = await data.json();
return results;
};

const fetchTemplate = async (params) => {
    const data = await fetch(`${API_URL}/api/gpt/getTemplate`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
    });
    const results = await data.json();
    if ("Service Unavailable" === results.message) {
        return await fetchTemplate(params);
    }
    return results;
}

export {saveFunction, postFunction, fetchPost, fetchDrafts, fetchTemplate, fetchPosts, API_URL};