import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Iconify from '../iconify/iconify';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { AuthContext } from 'src/auth/AuthContext';
import PaymentCardListDialog from './payment-card-list-dialog';
import { API_URL } from '../utilities';
import CustomCardElement from './CustomCardElement';

export default function useCardPicker() {
    const [selectedCard, setSelectedCard] = useState(null);
    const [cardList, setCardList] = useState([]);

    const { user } = useContext(AuthContext);
      
  useEffect(() => {
    const getUserCards = async () => {
      const response = await fetch(`${API_URL}/api/payment/cards/${user?.customerId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setCardList(data?.paymentMethods?.data);
      console.log('data', data);
      console.log('user cards', data?.paymentMethods?.data);
    };
    getUserCards();
  }, [setCardList, user?.customerId]);

  
    const openCards = useBoolean(false);
    const handleSelectCard = useCallback((newValue) => {
        setSelectedCard(newValue);
      }, []);
      // TODO have this track primary instead of just getting first one
    // const primaryCard = cardList[0];
    useEffect(() => {
      if (!cardList || cardList.length === 0) {
        return;
      }
    //   setSelectedCard(cardList.filter((card) => card.primary)[0]);
    setSelectedCard(cardList[0])
    }, [cardList, setSelectedCard]);

    const customCardElement = useMemo(() => {
      return React.createElement(CustomCardElement);
    }, []);

    return { selectedCard, cardList, openCards, handleSelectCard, customCardElement};
};

function CardPickerComponent({ selectedCard, cardList, openCards, handleSelectCard, customCardElement }) {
    return (
      <>
        <Grid xs={12} md={8}>
          <Button
            onClick={openCards.onTrue}
            endIcon={<Iconify width={16} icon="eva:arrow-ios-downward-fill" />}
            sx={{ typography: 'subtitle2', p: 0, borderRadius: 0 }}
          >
            {selectedCard?.card?.last4}
          </Button>
        </Grid>
        <PaymentCardListDialog
          list={cardList}
          open={openCards.value}
          onClose={openCards.onFalse}
          selected={(selectedId) => selectedCard?.id === selectedId}
          onSelect={handleSelectCard}
          customCardElement={customCardElement}
        />
      </>
    );
  }

  export { useCardPicker, CardPickerComponent};