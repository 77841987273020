import {useState, useEffect, useRef, useContext} from 'react';
import { AuthContext } from 'src/auth/AuthContext';
import { API_URL } from 'src/components/utilities';

const useWSS = ({
    onClose = () => {},
    content = '',
    setContent = () => {},
}) => {
    const { user, setUser } = useContext(AuthContext);
    const numTokensReceived = useRef(0);
    const typingMessageRef = useRef('');
    const [streamQueue, setStreamQueue] = useState([]);
    const [typing, setTyping] = useState(false);
    const [typingMessage, setTypingMessage] = useState('');
    const enqueueStreamMessage = (message) => {
      setTypingMessage(prev => prev + message);
      typingMessageRef.current += message;
      // setStreamQueue(prevStreamQueue => [...prevStreamQueue, message]);
    };

    const inputRefState = useRef({current: null});
    const [contentListener, setContentListener] = useState(content);

    const handleStream2 = (data, start, end) => {
      const newSocket = new WebSocket('wss://pandawhale.solutions:3000');
      newSocket.onopen = () => {
        console.log('WebSocket connection established');
        // Perform setup tasks or send initial messages here
        // setTypingMessage('');
        newSocket.send(JSON.stringify(data));

      };

      newSocket.onmessage = (event) => {
        const message = event.data;
        // Process the received message
        const parsedMessage = JSON.parse(message).message;
        console.log("new message", parsedMessage)
        setTypingMessage(prev => prev + parsedMessage);
      };
    }
  
    const handleStream = (data, inputRef) => {
      setTypingMessage('');
      setTyping(true);
      typingMessageRef.current = '';
      setStreamQueue([]);
      const newSocket = new WebSocket('wss://pandawhale.solutions:3000');
      newSocket.onopen = () => {
        console.log('WebSocket connection established');
        // Perform setup tasks or send initial messages here
        // setTypingMessage('');
        newSocket.send(JSON.stringify(data));

      };
  
      let start = 0;
      let end = 0;
      if (inputRef) {
        start = inputRef.current.selectionStart;
        end = inputRef.current.selectionEnd;
      }
      let totalMessage = "";
      newSocket.onmessage = (event) => {
        const message = event.data;
        // Process the received message
        const parsedMessage = JSON.parse(message).message;
        totalMessage += parsedMessage;
        console.log("new message", parsedMessage, numTokensReceived.current)
        if (parsedMessage.match(/ /g)) {
          numTokensReceived.current += parsedMessage.match(/ /g).length;
        }
        enqueueStreamMessage(parsedMessage);
        if (inputRef) {
          const newText = contentListener.substring(0, start) + totalMessage + contentListener.substring(end);
      
          console.log("changing info", start, end, newText);
          setContent(newText);
          setContentListener(newText);
          
          // After React updates the input value, set the cursor position right after the inserted text
          inputRef.current.setSelectionRange(start + parsedMessage.length, start + parsedMessage.length);
      }
      };

      newSocket.onclose = (event) => {
        console.log("closing socket", numTokensReceived.current);
        setUser({...user, tokens: Math.max(0, user.tokens - numTokensReceived.current)})
        decreaseRemoteTokens(user._id, numTokensReceived.current);
        numTokensReceived.current = 0;
        setTyping(false);
        onClose();
      }
    };

    useEffect(()=> {
        if (streamQueue.length === 0) {
          console.log("stream is empty");
          return;
        }
        const newMessage = streamQueue[streamQueue.length - 1];
        console.log("new message", newMessage, inputRefState, "input ref state");

        if (inputRefState) {
            const start = inputRefState.current.selectionStart;
            const end = inputRefState.current.selectionEnd;
            const newText = contentListener.substring(0, start) + newMessage + contentListener.substring(end);
        
            console.log("changing info", start, end, newText);
            setContent(newText);
            setContentListener(newText);
            
            // After React updates the input value, set the cursor position right after the inserted text
            inputRefState.current.setSelectionRange(start + newMessage.length, start + newMessage.length);
        }

        setTypingMessage(prev => prev + newMessage);
        if (streamQueue.length === 0) {
          setTyping(false);
        }
      }, [streamQueue, contentListener, setContent, setContentListener]);

    return [
        typing,
        setTyping, 
        typingMessage,
        setTypingMessage,
        handleStream,
        handleStream2
    ]
}

const decreaseRemoteTokens = async (userId, numTokens) => {
  
  const params = new URLSearchParams();
  params.append('userId', userId);
  params.append('decrement', numTokens);
  await fetch(`${API_URL}/api/users/decreaseTokens`, {
      method: 'POST',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
      },
      body: params.toString(),
    // Pass any required data like planID, customerID etc.
  });
}

export default useWSS;