import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { AuthContext } from '../AuthContext';
import useEmailOTP from '../hooks/useEmailOTP';
import API_URL from '../../api/API_URL';

const ForgotPassword = ({ handleAttemptToClose: close, handleChangeModal: move }) => {
    const {
        isForgotPasswordModalVisible,
        setVerifSeed,
        setEmail: setAuthEmail,
        errorMessage, setErrorMessage,
    } = useContext(AuthContext);

    const [successMessage, setSuccessMessage] = useState('');
    const { verifLoading, failureMessage, handleEmailOTP } = useEmailOTP(setVerifSeed);

    const [email, setLocalEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const checkUserExisting = useCallback(async (localEmail) => {
        try {
          const response = await fetch(`${API_URL}/api/users/exists?email=${localEmail}`, {
            method: 'GET', // Adjust the HTTP method as needed
            headers: {
              'Content-Type': 'application/json', // Adjust headers as needed
              // Add other headers if required
            },
          });
    
          const userBody = await response.json();
          if (!userBody?.exists) {
            setErrorMessage('Email is not in our database. Sign up to create an account.');
            return false;
          }
        } catch (error) {
          console.error(error);
          return null;
        }
        return true;
      }, [setErrorMessage]);

    const handleAttemptToClose = () => {
        close();
    }

    const handleSubmit = useCallback(async () => {
        setAuthEmail(email);
        setLoading(true);
        const exists = await checkUserExisting(email);
        setLoading(false);
        if (!exists) {
            return;
        }
        const response = await handleEmailOTP(email);
        if (response?.status === 'Email sent') {
            setSuccessMessage("Email sent");
            setTimeout(() => {
                move("verify");
            }, 500);
        }
    }, [email,
        // these are just to get rid of eslint errors, I don't expect them to change
        move, setAuthEmail, handleEmailOTP, checkUserExisting,]);

    return (
        <Dialog
            open={isForgotPasswordModalVisible}
            onClose={handleAttemptToClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                  width: '450px', 
              },
            }}
        >
            <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
            <DialogContent>
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={e => setLocalEmail(e.target.value)}
                />
                {verifLoading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
                    </div>
                )}
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
                    </div>
                )}
                {failureMessage && (
                    <Alert severity="error">
                        {failureMessage}
                    </Alert>
                )}
                {successMessage && (
                    <Alert severity="success">
                        {successMessage}
                    </Alert>
                )}
                {errorMessage && (
                    <Alert severity="error">
                        {errorMessage}
                        </Alert>
                )}
                <Link variant="body2" color="primary" component="p" onClick={() => {
                    move("login");
                }}>
                    Back to Login
                </Link>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAttemptToClose} color="primary" variant="soft">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ForgotPassword.propTypes = {
    handleAttemptToClose: PropTypes.func,
    handleChangeModal: PropTypes.func,
}

ForgotPassword.defaultProps = {
    handleAttemptToClose: () => { },
    handleChangeModal: () => { },
}

export default ForgotPassword;
