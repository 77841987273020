import React, { useState, useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
//
import PaymentSummary from './payment-summary';
import PaymentMethods from './payment-methods';
import PaymentBillingAddress from './payment-billing-address';
import Paper from '@mui/material/Paper';
import { API_URL } from '../utilities';
import { AuthContext } from 'src/auth/AuthContext';
import useCardPicker, {CardPickerComponent} from './card-picker';
// ----------------------------------------------------------------------

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "white",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

export default function PaymentView() {
  const {  user, setUser } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const payload = location.state && location.state.payload;
        const stripe = useStripe();
        const elements = useElements();
        const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    const { selectedCard, handleSelectCard, cardList, openCards, customCardElement } = useCardPicker();
        
        const handleSubmit = async (event) => {
          event.preventDefault();
          setLoading(true);

          // const cardElement = elements.getElement(CardElement);

          // const { error, paymentMethod } = await stripe.createPaymentMethod({
          //   type: 'card',
          //   card: cardElement,
          // });

          // if (error) {
          //   setErrorMessage(error.message);
          //   return;
          // }
          
          // Get client secret from your backend
          try {
            const params = new URLSearchParams();
            params.append('email', user.email);
            params.append('paymentMethodId', selectedCard?.id);
            params.append('priceId', payload.priceId);
            params.append('userId', user._id);
            const response = await fetch(`${API_URL}/api/payment/create-subscription`, {
                method: 'POST',
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: params.toString(),
              // Pass any required data like planID, customerID etc.
            });

            const data = await response.json();
            
            const { client_secret, success, user: updatedUser } = data;
            if (updatedUser) {
              setUser({...user, subscriptionTier: updatedUser.subscriptionTier, subscriptionId: updatedUser.subscriptionId});
            }
            // TODO: maybe this should be if !client_secret instead
            if (!client_secret) {
              navigate('../success');
            }
            // else, if there is a confirm card action needed, do more
            const result = await stripe.confirmCardPayment(client_secret, {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            });
            
            if (!result.error || result.error.message == "You cannot confirm this PaymentIntent because it has already succeeded after being previously confirmed.") {
              console.log('Subscription successful!');
              // update mongodb
              const params = new URLSearchParams();
              params.append('subscriptionId', user.subscriptionId);
              params.append('priceId', payload.priceId);
              params.append('userId', user._id);
              await fetch(`${API_URL}/api/payment/updateSubscriptionMongo`, {
                  method: 'POST',
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded"
                  },
                  body: params.toString(),
                // Pass any required data like planID, customerID etc.
              });
              navigate('../success');
            } else {
              console.error(result.error);
              setErrorMessage(result.error.message);
            }
          } catch (error) {
            console.error('There was an error!', error);
            setErrorMessage(error.message);
          } finally {
            setLoading(false);
          }
        };

  return (
    <Container
      sx={{
        pt: 15,
        pb: 10,
        minHeight: 1,
      }}
    >
      <Typography variant="h3" align="center" sx={{ mb: 2 }}>
        {`Let's finish powering you up!`}
      </Typography>

      <Typography align="center" sx={{ color: 'text.secondary', mb: 5 }}>
        {payload.subscription} plan is right for you.
      </Typography>

      <Grid container rowSpacing={{ xs: 5, md: 0 }} columnSpacing={{ xs: 0, md: 5 }}>
        <Grid xs={12} md={8}>
          <Box
            gap={5}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
            }}
            sx={{
              p: { md: 5 },
              borderRadius: 2,
              border: (theme) => ({
                md: `dashed 1px ${theme.palette.divider}`,
              }),
            }}
          >
            <PaymentBillingAddress />

            <Stack spacing={5}>
        <Typography variant="h6">Payment Method</Typography>
        <Paper style={{ borderRadius: '8px', padding: '20px 10px 20px 10px' }}>
      {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}
      {/* <CardPickerComponent selectedCard={selectedCard} cardList={cardList} openCards={openCards} handleSelectCard={handleSelectCard} customCardElement={customCardElement} /> */}
      {customCardElement}
    </Paper>

    {errorMessage && <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>}
            </Stack>
          </Box>
        </Grid>

        <Grid xs={12} md={4}>
        <form onSubmit={handleSubmit}>
          <PaymentSummary loading={loading} price={payload.price} subscription={payload.subscription} />
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
