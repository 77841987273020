import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import {AuthContext} from './AuthContext';
import SignUp from './modals/SignUp';
import LogIn from './modals/LogIn';
import ForgotPassword from './modals/ForgotPassword';
import Verification from './modals/Verification';
import Paywall from './modals/Paywall';

const AuthModal = ({onLoad=true, cancellable}) => {
    const {
      setIsLoginModalVisible,
      setIsSignupModalVisible,
      setIsForgotPasswordModalVisible,
      isVerificationCodeModalVisible,
      setIsVerificationCodeModalVisible,
      setIsPaywallModalVisible,
      user,
      setOnVerifySuccess,
      setErrorMessage,
      } = useContext(AuthContext);

      const clearModals = () => {
        setIsLoginModalVisible(false);
        setIsSignupModalVisible(false);
        setIsForgotPasswordModalVisible(false);
        setIsVerificationCodeModalVisible(false);
        setIsPaywallModalVisible(false);
        setErrorMessage(null);
      }

      const [isAttemptedToClose, setIsAttemptedToClose] = useState(false);

      const isLoggedIn = useMemo(()=> !!user, [user]);

      const numberInteractionsExceedsFree = false;
      useEffect(()=> {
        if (numberInteractionsExceedsFree && onLoad && !isLoggedIn) {
            setIsLoginModalVisible(true);
        } else {
            setIsLoginModalVisible(false);
        }
      }, [user, isLoggedIn, onLoad, numberInteractionsExceedsFree, setIsLoginModalVisible]);

      const handleModalVisibility = (type, isVisible) => {
        if (type === "login") {
          setIsLoginModalVisible(isVisible);
        } else if (type === "signup") {
          setIsSignupModalVisible(isVisible);
        }
      };

      const handleChangeModal = (type, successCallback) => {
        clearModals();
        switch (type) {
          case "login":
            setIsLoginModalVisible(true);
            break;
          case "signup":
            setIsSignupModalVisible(true);
            break;
          case "forgot":
            setIsForgotPasswordModalVisible(true);
            break;
          case "verify":
            setIsVerificationCodeModalVisible(true);
            if (successCallback) {
              setOnVerifySuccess(async () => successCallback);
            } else {
              setOnVerifySuccess(()=>{}); // reset so each time go to verify, it doesn't use the previous callback
              // needed because verify is reused by multiple flows
            }
            break;
          default:
            break;
        }
      }
      
      const handleAttemptToClose = () => {
        if (cancellable) {
            clearModals(true);
            return;
        }
        if (!isLoggedIn) {
          setIsAttemptedToClose(true);
        }
      };

      return (<>
              {isAttemptedToClose && (
          <Alert
            message="Please sign up or log in to continue."
            type="error"
            showIcon
            style={{ marginBottom: '10px' }}
          />
        )}
        {/* forcibly unmount using conditional rendering */}
      <LogIn handleAttemptToClose={handleAttemptToClose} handleModalVisibility={handleModalVisibility} handleChangeModal={handleChangeModal} />
<ForgotPassword handleAttemptToClose={handleAttemptToClose} handleModalVisibility={handleModalVisibility} handleChangeModal={handleChangeModal} />
    <SignUp handleAttemptToClose={handleAttemptToClose} handleModalVisibility={handleModalVisibility} handleChangeModal={handleChangeModal} />
    {isVerificationCodeModalVisible && <Verification handleAttemptToClose={handleAttemptToClose} handleModalVisibility={handleModalVisibility} handleChangeModal={handleChangeModal} />}
    <Paywall handleAttemptToClose={handleAttemptToClose} handleModalVisibility={handleModalVisibility} handleChangeModal={handleChangeModal} />
    </>
      );
}

AuthModal.propTypes = {
  onLoad: PropTypes.bool,
  cancellable: PropTypes.bool,
};

export default AuthModal;