import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
// hooks
import { useCountdownDate } from 'src/hooks/use-countdown';
// _mock
import { _socials } from 'src/_mock';
// assets
import { ComingSoonIllustration } from 'src/assets/illustrations';
// components
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function SuccessView() {

    const navigate = useNavigate();
  return (
    <Container component="main" maxWidth="sm">
    <Stack 
    sx={{my: 10}}
    padding={1}
    spacing={2}
    >
      <Typography variant="h3" sx={{ mb: 2 }}>
        Success!
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        Feel free to return to what you were doing. Don't worry - your progress was saved!
      </Typography>

      <Button
      size='large'
      color='primary'
      variant="contained" sx={{ mt: 5 }} onClick={()=> {
        navigate(-2);
      }} >
        Back to Writing
      </Button>

      <ComingSoonIllustration sx={{ my: 10, height: 240 }} color="green" />

      <Stack spacing={1} alignItems="center" justifyContent="center" direction="row">
        {_socials.map((social) => (
          <IconButton
            key={social.name}
            sx={{
              color: social.color,
              '&:hover': {
                bgcolor: alpha(social.color, 0.08),
              },
            }}
          >
            <Iconify icon={social.icon} />
          </IconButton>
        ))}
      </Stack>
    </Stack>
    </Container>
  );
}

// ----------------------------------------------------------------------

function TimeBlock({ label, value }) {
  return (
    <div>
      <Box> {value} </Box>
      <Box sx={{ color: 'text.secondary', typography: 'body1' }}>{label}</Box>
    </div>
  );
}

TimeBlock.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
