import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import './styles/WritePage.css';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import avatar1 from '../../images/harry_potter.jpg';
import avatar2 from '../../images/twilight.jpg';
import avatar3 from '../../images/marvel.jpg';
import avatar4 from '../../images/star_wars.jpg';
import avatar5 from '../../images/lotr.jpg';
import avatar6 from '../../images/pride_and_prejudice.jpg';
import avatar7 from '../../images/game_of_thrones.jpg';
import avatar8 from '../../images/percy_jackson.jpg';
import avatar9 from '../../images/custom.jpg';
import { FanverseContext } from './FanverseContext';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const AvatarsRow = ({promptIndex, setPromptIndex}) => {
  const theme = useTheme();
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9];
  const avatarTooltips = ['Harry Potter', 'Twilight', 'Marvel', 'Star Wars', 'Lord of the Rings', 'Pride and Prejudice', 'Game of Thrones', 'Percy Jackson', 'Custom'];
  const { fanverseIndex, setFanverseIndex } = useContext(FanverseContext);
    
  return (
    <div
      style={{
          display: 'flex', 
          flex: 1,
          overflowY: 'hidden',
          maxHeight: 120,
          minHeight: 120,
      }}
    >
    <div className="avatarScroll">
        {avatars.map((avatarSrc, index) => (
          <Tooltip key={index} title={avatarTooltips[index]}>
            <Avatar
              alt={`Avatar ${fanverseIndex+1}`}
              src={avatarSrc}
              onClick={() => {
              //   setSelectedAvatar(index);
              //   setPromptIndex(index);
              setFanverseIndex(index);
            }
              }
              sx={{ 
                width: 80, 
                height: 80, 
                marginRight: 5,
                boxShadow: fanverseIndex === index ? '0px 0px 10px 5px rgb(71, 12, 122, 0.9)' : '0px 0px 10px 5px rgba(0, 0, 0, 0.3)', 
                cursor: 'pointer',
                display: "inline-block",
                verticalAlign: "middle",
              }}
            />
          </Tooltip>
        ))}
    </div>
    </div>
  );
};

export default AvatarsRow;