import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faPaperPlane, faShare } from '@fortawesome/free-solid-svg-icons';
import Message from './Message';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import prompts from '../../content/prompts';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import FanverseRow from './FanverseRow';
import { FanverseContext, FanverseProvider } from './FanverseContext';
import StyledButton from '../../common/StyledButton';
import useWSS from '../../common/useWSS';
import Pagination from '@mui/material/Pagination';
// import './styles/WritePage.css';
import { StyledEditor } from '../editor/styles';
import { AuthContext } from 'src/auth/AuthContext';

const WritePage = () => {
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const navigate = useNavigate();
    const { fanverseIndex} = useContext(FanverseContext);
    const {isPreSignupTrialExpired, isPrePayTrialExpired, setIsSignupModalVisible, setIsPaywallModalVisible, setNumInteracts, user } = useContext(AuthContext);
    const [prevFanverseIndex, setPrevFanverseIndex] = useState(fanverseIndex);
    const [messageObjects, setMessageObjects] = useState(prompts.map((prompt)=> (
        [
            {
                isBot: false,
                text: prompt
            }
        ]
    )))

    const messages = messageObjects[fanverseIndex];

    const addMessage = useCallback((newMessage, index = fanverseIndex)=> {
        const fanverseMessageHistory = messageObjects[index];
        const newFanverseMessageHistory = [...fanverseMessageHistory, newMessage];
        messageObjects[index] = newFanverseMessageHistory;
        setMessageObjects(messageObjects);
    }, [messageObjects, fanverseIndex])
    
    const [input, setInput] = useState('');

    const handleNextClick = (event) => {
        event.preventDefault();
        console.log('Next button clicked');
        const allMessagesText = messages.map(message => message.text).join("\n") + typingMessage + input;
        navigate('../editor',
            {state: allMessagesText}
        );
    };

    const [typing, setTyping, typingMessage, setTypingMessage, handleStream] = useWSS({});
    
    const handleSubmit = useCallback((e, useTextboxInput) => {
        e.preventDefault();

        if (!user) {
            if (isPreSignupTrialExpired("interact")) {
              setIsSignupModalVisible(true);
              return;
            }
            setNumInteracts((prev) => prev + 1);
          }

        // if (!user?.subscriptionTier || user?.subscriptionTier === "Free") {
        //     if (isPrePayTrialExpired("interact", user)) {
        //       setIsPaywallModalVisible(true);
        //       return;
        //     }
        //     setNumInteracts((prev) => prev + 1);
        //   }

        if (user?.tokens <= 0) {
            setIsPaywallModalVisible(true);
            return;
        }

        addMessage(typingMessageInfo);
        setTyping(false);

        const newMessage = {
            isBot: false,
            text: input
        };

        if (useTextboxInput) {
            addMessage(newMessage);
            setInput('');
        }

        handleStream({
            "userInteractionContent": messageObjects[fanverseIndex].filter(k=>!k.isBot).map(k=> k.text).join("")
        });
    

    }, [input, typingMessage, messageObjects, addMessage, handleStream, user]);

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        if (typing) {
            //removed because fanafic refactor broke this by not using a scrolling inner div. so this line was scrolling the parent instead of the inner div
            // scrollToBottom()
        }
      }, [typingMessage, typing]);

      useEffect(()=> {
        if (typingMessage) {
            // const [state, setState] = messageObjects[prevFanverseIndex];
            // setState(previousMessages => [...previousMessages, typingMessageInfo]);
            addMessage(typingMessageInfo, prevFanverseIndex);
            setTypingMessage("");
        }
        setPrevFanverseIndex(fanverseIndex);
      }, [fanverseIndex])


      // on navigate away from page, save the current messageObjects to local storage
      // and then on navigate back to page, load the messageObjects from local storage
      useEffect(()=> {
        setLoading(true);
        const messageObjects = JSON.parse(localStorage.getItem('messageObjects'));
        const localInput = localStorage.getItem('input');
        if (messageObjects) {
            setMessageObjects(messageObjects);
        }
        if (localInput) {
            setInput(localInput);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        return () => {
            localStorage.setItem('messageObjects', JSON.stringify(messageObjects));
            localStorage.setItem('input', input);
        }
      }, [input]);

    const typingMessageInfo = {
        isBot: false,
        text: typingMessage
    }

    return (
        <div style={{ backgroundColor: theme.palette.background.default, flex: 1, marginBottom: '40px' }}>
            {/* TODO: implement share */}
            {/* <div className="right-button">
                <Tooltip title="Share with friends, let them play your story!">
                    <button className="icon-button" style={{ fontSize: '1.5rem', marginRight: '10px' }} onClick={() => console.log("Share clicked!")}>
                        <FontAwesomeIcon icon={faShare} />
                    </button>
                </Tooltip>
            </div> */}
            <Container>
            <FanverseRow />
            <Card>
            <CardContent>
            <StyledEditor>
                {messages.filter(a => a.text).map((message, index) => (
                    <Message key={index} {...message} />
                ))}
              <Message key={-1} {...typingMessageInfo} />
                <div ref={messagesEndRef} >
                {!typing &&     
                <div style={{padding: '20px', marginBottom: '20px', flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Button variant="contained" style={{alignSelf: 'center'}} color="primary" onClick={handleSubmit}>
      Generate More
    </Button></div>}
    </div>
            </StyledEditor>
            </CardContent>
            </Card>
            <div style={{ border: `1px solid ${theme.palette.divider}`, borderRadius: '15px', marginTop: '20px', backgroundColor: theme.palette.grey[800] }}>
                <form className="input-field" onSubmit={(e) => handleSubmit(e, true)}>
                    <Tooltip title="Stuck? Let AI write something for you!">
                        <button className="icon-button" onClick={handleSubmit}>
                            <FontAwesomeIcon icon={faRobot} />
                        </button>
                    </Tooltip>
                                      {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
                </div>
              )
            : 
            <input type="text" value={input} onChange={e => setInput(e.target.value)} placeholder="Type here to continue the story..."
            style={{
                "--placeholder-color": theme.palette.secondary.light,
                "--placeholder--font": theme.typography.body1.fontFamily,
            }} 
            />}
                    <Button variant="contained" style={{alignSelf: 'center'}} color="primary" onClick={e => handleSubmit(e, true)}>
                        Submit
                    </Button>
                </form>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination count={2} color="primary" onClick={handleNextClick}/>
            </div>
            </Container>
        </div>
    );
}

const WrappedWritePage = () => {
    return (
        <FanverseProvider>
            <WritePage />
        </FanverseProvider>
    )
}

export default WrappedWritePage;
