import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Container } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { _userPlans as _pricingPlans } from 'src/_mock';
import PricingCard from './pricing-card';
import { AuthContext } from '../AuthContext';

const Paywall = ({handleAttemptToClose, handleChangeModal}) => {
    const navigate = useNavigate();
    const {
        isPaywallModalVisible,
      } = useContext(AuthContext);
    return (
        <Dialog
            open={isPaywallModalVisible}
            onClose={handleAttemptToClose}
            PaperProps={{ style: { width: '80vw', maxWidth: 'none' } }}
        >
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
            <Container>
                <Box
                gap={{ xs: 3, md: 0 }}
                display="grid"
                alignItems={{ md: 'center' }}
                gridTemplateColumns={{ md: 'repeat(3, 1fr)' }}
                >
                    {_pricingPlans.map((card, index) => (
                    <PricingCard key={card.subscription} card={card} index={index} onClick={()=> {
                        handleAttemptToClose();
                        navigate('/payment', { state: { payload: card } });
                    }
                     } />
                    ))}
                </Box>
            </Container>
            </DialogContent>
        </Dialog>  
    );
}

Paywall.propTypes = {
    handleAttemptToClose: PropTypes.func.isRequired,
    handleChangeModal: PropTypes.func.isRequired,
}

export default Paywall;