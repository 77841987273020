import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

// routes
import { paths } from 'src/routes/paths';
// components
import Editor from 'src/components/editor';
import Markdown from 'src/components/markdown';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import styled, { createGlobalStyle } from 'styled-components';
import Alert from '@mui/material/Alert';
import useWSS from 'src/common/useWSS.js';
import StyledButton from 'src/common/StyledButton';
import { saveFunction, fetchPost } from '../utilities';
import { AuthContext } from 'src/auth/AuthContext';

const chipOptions = [
    'describe', 'eloquent', 'brainstorm', 'slangy', 'mystical', 'epic',
    'romanticize', 'intensify', 'gritty', 'suspenseful', 'funny', 'adventurous',
    'medieval', 'victorian', 'noir', 'satirical', 'edgy', 'cryptic',
    'tragic', 'apocalyptic'
];
// ----------------------------------------------------------------------

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-height: 3.4rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const ChipSelector = ({ chipOptions, handleOptionClick }) => {
    return (
        <StyledButtonContainer>
        {chipOptions.map(option => (
          <StyledButton key={option} onClick={() => handleOptionClick(option)}>
            {option}
          </StyledButton>
        ))}
      </StyledButtonContainer>
    )
        }

export default function EditorView() {
  const globalTheme = useTheme();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(2);
  const [selectedText, setSelectedText] = useState('');
  const [quillSimple, setQuillSimple] = useState('');

  const [quillFull, setQuillFull] = useState('');
  const [transformedText, setTransformedText] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePageChange = (event, value) => {
    if (value === 1) {
        navigate('/write');
    } else if (value === 2) {
        navigate('/editor');
    }
  };

  // sets content based on global state, write/edit/share pages
  const { state: content } = useLocation();
  useEffect(() => {
    setQuillFull(content);
  }, [content]);

  // on navigate away from page, save the current messageObjects to local storage
  // and then on navigate back to page, load the messageObjects from local storage
  useEffect(()=> {
    const localInput = localStorage.getItem('editInput');
    // prioritize the remote content over the local content
    if (content) {
      return;
    }
    if (localInput) {
        setQuillFull(localInput);
    }
    }, []);

  const [draftId, setDraftId] = useState(null);
  useEffect(() => {    
    const url = new URL(window.location.href);

    // Retrieve the value of the "id" parameter from the URL
    const id = url?.searchParams?.get('id');
    if (id) {
      fetchPost(id).then((post)=> {
        setQuillFull(post.content);
        if (post.draft) {
          setDraftId(post._id);
        }
      })
    } else {
    }

  }, [])

  const {isPreSignupTrialExpired, isPrePayTrialExpired, setIsSignupModalVisible, setIsPaywallModalVisible, setNumFilterUses, user } = useContext(AuthContext);

  const inputRef = useRef(null);
  const [unused1, unused2, typingMessage, setTypingMessage, unused5, handleStream] = useWSS({
    content: transformedText, 
    setContent: setTransformedText,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [saveAlert, setSaveAlert] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const handleOptionClick = (option) => {
    if (!user?.subscriberLevel) {
      // if (isPrePayTrialExpired("filter")) {
      //   setIsPaywallModalVisible(true);
      //   return;
      // }
      if (user?.tokens <= 0) {
        setIsPaywallModalVisible(true);
        return;
    }
      setNumFilterUses((prev) => prev + 1);
    }
    if (!user) {
      if (isPreSignupTrialExpired("filter")) {
        setIsSignupModalVisible(true);
        return;
      }
      setNumFilterUses((prev) => prev + 1);
    }
    if (!selectedText.trim()) {
      // If no text has been selected, show an error alert
      setShowAlert(true);
      return;
    } else {
      setShowAlert(false);
      setSelectedOption(option);
      setTypingMessage('');
      console.log("option", option, selectedText)
      handleStream({
        "filterPrompt": {
            "filter": option
        },
        "userInteractionContent": selectedText
    }, inputRef)
    }
  };

  const [saveDisabled, setSaveDisabled] = useState(false);
  const handleSave = () => {
    setSaveDisabled(true);
    setLoading(true);
    saveFunction(quillFull, draftId, user._id).then((post)=> {
      console.log("POST", post);
      setDraftId(post._id);
      setSaveAlert(true);
    }).catch(()=> {
      setSaveDisabled(false);
    }).finally(() => {
      setSaveDisabled(false);
      setLoading(false);
    });
  };

    useEffect(() => {
        return () => {
            localStorage.setItem('editInput', quillFull);
        }
      }, [quillFull]);


  return (
    <>
      <Container sx={{ my: 10 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={8}>
            <Card>
              <CardContent>
                <Editor
                  id="full-editor"
                  value={quillFull}
                  onChange={(value) => setQuillFull(value)}
                  onChangeSelection={(range, source, editor) => {
                    const selection = editor.getSelection();
                    if (selection) {
                        setSelectedText(editor.getText(selection.index, selection.length));
                    }
                }}
                />
              </CardContent>
              <Stack direction="row" spacing={1} sx={{ p: 3, borderRadius: 1 }}>
                <Button color="primary" variant="contained" fullWidth onClick={handleSave}>
                  Save
                </Button>
                {/* <Button color="primary" variant="outlined" fullWidth>
                  Publish
                </Button> */}
              </Stack>
            </Card>
            <Box mt={3}>
              {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="inherit" size={30} sx={{ p: 1 }} />
                </div>
              )}
              {saveAlert && (
                <Alert fullWidth severity="success" onClose={() => setSaveAlert(false)}>
                  Save was successful! View in your <Link to="/profile" style={{ fontSize: 'inherit', color: 'inherit', textDecoration: 'underline' }}>profile</Link>.
                </Alert>
              )}
            </Box>
          </Grid>

          <Grid xs={12} md={4}>
            <Stack sx={{ p: 3, borderRadius: 2, bgcolor: 'background.neutral' }}>
            <ChipSelector chipOptions={chipOptions} handleOptionClick={handleOptionClick} />
            <Divider sx={{ my: 1 }} />
              <Typography variant="h6" sx={{ mb: 2 }}>
                Original Text
              </Typography>
              {selectedText ? 
              <><Markdown children={selectedText} /> {!selectedOption && <Typography style={{color: globalTheme.palette.primary.light}}>{<i>Now click a style above!</i>}</Typography>}</> : 
              <Typography>{<i>Select some text in the textbox</i>}</Typography>}

              <Divider sx={{ my: 5 }} />

              <Typography variant="h6" sx={{ mb: 2 }}>
                Transformed
              </Typography>
              <Typography>{typingMessage || <i>Click on a style filter above</i>}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Pagination
          count={2}
          color="primary"
          page={currentPage}
          onChange={handlePageChange}
        />
        </div>
      </Container>
    </>
  );
}
