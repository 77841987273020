import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${props => props.transformButton ? 'rgb(220, 184, 255)' : 'white'};
  border: 1px solid ${props => props.noBorder ? 'transparent' : 'black'};
  border-radius: 20px;
  color: rgb(85, 65, 81); //textcolor
  margin: 0 4px;
  &:hover {
    background-color: rgb(240, 219, 255);
    border-color: rgb(95, 43, 146) !important;
    color: rgb(102, 90, 111) !important;
  }
  &:active {
    background-color: #e6e6e6;
    border-color: #1890ff;
  }
  ${props => props.flexGrow && 'flex-grow: 1;'}
`;

export default StyledButton;