import React from 'react'
import '../style/home.scss'
import lines from '../images/lines.png'
import mobile from '../images/iphone.png'
import { motion } from 'framer-motion';
import About from './About'
import Contact from './Contact'
import { useNavigate } from 'react-router-dom';
import video from '../videos/chatvideo.mp4';

function Home() {
  const navigate = useNavigate();

  return (
    <div className='home' id='home'>
      <div className='home__relative'>
        <div className='home__inner'>
          <div className="container">
            <div className="content">
              <h1>Kunei is an app to create and
              discover entertaining stories. 
              </h1>
              <br></br>
              <h1>With the power of AI, we
              make it easy and fun to read
              and write.</h1>
            </div>
            <button className="bottom-button" onClick={() => navigate('/write')}>Get started</button>
          </div>

          <div className="container sec">
            <div className="content second">
            <h1>Kunei is an app to create and
              discover entertaining stories. 
              </h1>
              <br></br>
              <h1>With the power of AI, we
              make it easy and fun to read
              and write.</h1>
            </div>
          </div>
          {/* mobile picture on the top of the page */}
          <div className='mobile-vibrate'>
            <motion.div
              animate={{ rotate: [-10, 10, -10, 10, -10, 10, 0] }}
              transition={{ duration: 2.5 }}
              style={{ display: 'inline-block' }} 
              className='iphone'><img src={mobile}/></motion.div>
            <div className='lines'> <img src={lines}/></div>
          </div>
        </div>
        <div className="video-container">
            <video width="100%" height="100%" autoPlay loop muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        <About />
        {/* <Contact /> */}
      </div>
    </div>
  )
}

export default Home
