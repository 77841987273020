import { useState } from 'react';

const API_URL = "https://x0fh4vkjah.execute-api.us-east-1.amazonaws.com/kunei-email-verif"

const useEmailOTP = (setVerifSeed) => {
  const [loading, setLoading] = useState(false);
  const [failureMessage, setFailureMessage] = useState('');

  const handleEmailOTP = async (email) => {
    setLoading(true);
    try {
      const seed = Math.floor(Math.random() * 90) + 10;
      setVerifSeed(seed);
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ seed, email }),
      });

      console.log('Got response:', response);

      return response.json();
    } catch (error) {
      console.error(error);
      setFailureMessage('An error occurred. Please try again.');
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    verifLoading: loading,
    failureMessage,
    handleEmailOTP,
  };
};

export default useEmailOTP;