const styles = {
    textAreaStyle: {
        width: '100%',
        boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px',
        marginBottom: '1rem',
        padding: '12px',
        height: '100%'
      }
}

export default styles;