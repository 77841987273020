import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// @mui
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { API_URL } from '../utilities';
import { AuthContext } from 'src/auth/AuthContext';

// ----------------------------------------------------------------------

export default function PaymentCardItem({ card, sx, cardList, setCardList, ...other }) {
  const { user } = useContext(AuthContext);
  const popover = usePopover();

  return (
    <>
      <Stack
        spacing={1}
        component={Paper}
        variant="outlined"
        sx={{
          p: 2.5,
          width: 1,
          position: 'relative',
          ...sx,
        }}
        {...other}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify
            icon={(card?.card?.brand === 'visa' && 'logos:visa') || 'logos:mastercard'}
            width={36}
          />

          {card.primary && <Label color="info">Default</Label>}
          {card.new && <Label color="info">New</Label>}
        </Stack>

        <Typography variant="subtitle2">{card?.card?.last4}</Typography>

        <IconButton
          onClick={(event) => {
            popover.onOpen(event);
            event.stopPropagation();
          }}
          sx={{
            top: 8,
            right: 8,
            position: 'absolute',
          }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose}>
      <MenuItem onClick={async (event) => { 
            let data;
            try {
              const params = new URLSearchParams();
              params.append('paymentMethodId', card.id);
              params.append('customerId', user.customerId);
              const response = await fetch(`${API_URL}/api/payment/set-default-card`, {
                  method: 'POST',
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded"
                  },
                  body: params.toString(),
                // Pass any required data like planID, customerID etc.
              });
        
              data = await response.json();
            } catch (error) {
              console.error('There was an error!', error);
              // setErrorMessage(error.message);
            } finally {
              popover.onClose(event);
            }
        }}>
          <Iconify icon="eva:star-fill" />
          Set as primary
        </MenuItem>

        {/* <MenuItem onClick={popover.onClose}>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem> */}

        <MenuItem onClick={async (event) => {
                      let data;
                      try {
                        const params = new URLSearchParams();
                        params.append('paymentMethodId', card.id);
                        params.append('customerId', user.customerId);
                        const response = await fetch(`${API_URL}/api/payment/delete-card`, {
                            method: 'POST',
                              headers: {
                                "Content-Type": "application/x-www-form-urlencoded"
                            },
                            body: params.toString(),
                          // Pass any required data like planID, customerID etc.
                        });
                  
                        data = await response.json();
                        if (data?.success) {
                          deleteItem(cardList, setCardList, card.id);
                        }
                      } catch (error) {
                        console.error('There was an error!', error);
                        // setErrorMessage(error.message);
                      } finally {
                        popover.onClose(event);
                      }
        }
          } sx={{ color: 'error.main' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  );
}

const deleteItem = (cardsList, setCardsList, idToDelete) => {
  // Create a new array that excludes the item with the specified id
  console.log('cards', cardsList)
  const updatedItems = cardsList.filter((item) => item.id !== idToDelete);

  // Update the state with the new array
  setCardsList(updatedItems);
};

PaymentCardItem.propTypes = {
  card: PropTypes.object,
  sx: PropTypes.object,
};
