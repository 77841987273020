const prompts = [
    `Darkness had descended upon Hogwarts, and with it, a suffocating sense of despair. I found myself huddled in a hidden corner with Harry, Hermione, and Ron, the weight of the world on our shoulders. As we exchanged hushed words and shared our meager plans, I could see the doubt in their eyes. But as the night wore on, it became clear that I, the least likely of us all, would have to step forward and lead the charge. As the clock struck midnight, a message arrived that made my blood run cold, "The time has come. Choose your side."`,
    
    `Bella's heart raced as she stood in front of the Cullen house, a letter from Edward clutched tightly in her hands. It had been a week since he'd gone missing, and this was the first sign of him. The letter was cryptic, asking her to meet him at their special place, and signed with a simple 'E'. As the wind howled, she couldn't help but feel that something darker lurked behind his mysterious disappearance. Edward had been acting strange lately, even for a vampire. "Something I've been keeping from you."`,
    
    `Tony Stark's eyes widened as he examined the holographic data on the new element he had just synthesized in the lab. It wasn't supposed to exist, according to every rule of physics. Yet here it was, glowing with an ethereal light, and showing properties that could rewrite the laws of science. Just as he was about to call Bruce for a second opinion, an encrypted message flashed onto his screen. It was from Nick Fury, and it read: "Stark, we've got a situation. Assemble the team. And whatever you do, don't let that element out of your sight. It's not what you think it is."`,
    
    `The TIE Fighter's engines roared as I maneuvered through the debris field, dodging wreckage from the space battle that had taken place moments ago. Blaster fire streaked past my cockpit, and I gripped the control yoke, my heart pounding in my chest. The adrenaline surged through my veins, heightening my senses as I assessed the chaotic situation unfolding around me.

    Suddenly, a transmission crackled through my comm system, breaking through the chaos. "Pilot, this is Commander Korr. We need your support. We're pinned down by enemy forces near the abandoned space station. We won't last much longer. Get in there and clear them out!"`,
    `I stood on the hilltop, the wind rustling through my hair as I surveyed the vast expanse before me. The green fields stretched out, punctuated by the occasional cluster of trees, leading the eye to the distant mountains. The sunlight filtered through the clouds, casting a golden glow over the land. I felt a sense of awe and wonder at the beauty of Middle-earth.

    As I took in the view, a voice called out from behind me. "Quite a sight, isn't it?" I turned to see Aragorn approaching, his rugged features etched with a mixture of determination and weariness.`,
    
    `The air was perfumed with the essence of blooming roses as Elizabeth found herself, quite unanticipatedly, in the environs of Pemberley's gardens. One year hence their union in matrimony, Elizabeth and Mr. Darcy had discovered a comforting regularity in their daily existence, yet on this particular eve, a certain peculiarity hung in the atmosphere. Mr. Darcy had exhibited an uncharacteristic aloofness throughout the day, and now, as the sun took its leave, he advanced towards her with a countenance most grave.`,
    
    `The winds of winter had swept across the lands, and though the season had passed, an icy breath still lingered in the air. Arya Stark, no longer the child that had fled King's Landing all those years ago, returned to the shores of Westeros, a heavy burden of dark secrets weighing on her soul. Journeying through the dense woods of the Haunted Forest, a cave hidden from plain sight caught her eye, its entrance marked by the sigil of House Targaryen. Compelled by a force she could not quite comprehend, she stepped into the darkness of the cave. There, nestled amidst the shadows, lay a dragon's egg, its shell pulsating with warmth, a network of cracks beginning to spider across its surface.`,
    
    `The morning sun cast a warm glow over Camp Half-Blood, as the sound of laughter and training echoed through the air. I stood near the edge of the arena, watching as a group of demigods sparred with their celestial bronze weapons. Their movements were fluid and graceful, a testament to their training and heritage. As I observed, my attention was caught by a familiar voice calling out from behind me.

    "Hey! Haven't you seen since last summer! How you been?," called Percy Jackson, the legendary Son of Poseidon. He walked over with his signature mischievous grin, his sea-green eyes sparkling with excitement. "You ready for some action?"`,
    `Custom!`,
]

export default prompts;