import React, { useState, useEffect } from 'react';
import './ReadPage.css';
import { Button, Space, Tooltip, Switch, Modal, Input, Typography } from 'antd';
import { PlusOutlined, HeartFilled, MessageFilled, ShareAltOutlined, ExpandAltOutlined } from '@ant-design/icons';
import { IoMdShareAlt } from "react-icons/io";
import { fetchPost } from '../utilities';

const { Link } = Typography;

const ReadPage = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const [content, setContent] = useState("");

  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Retrieve the value of the "id" parameter from the URL
    const id = url.searchParams.get('id');

    // Use the "id" value as needed
    console.log('URL Parameter: ', id);
    fetchPost(id).then((post)=> {
      console.log('content', post);
      setContent(post);
    })
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const ActionButtons = () => (
    <>
      {!isMobileView && (
        <Space direction="horizontal" size="small" className={isMobileView ? "right-buttons-mobile" : ""}>
          <Tooltip title="Like">
            <HeartFilled style={{ fontSize: "24px", color: 'white', padding: "5px" }} />
          </Tooltip>
          <Tooltip title="Comment">
            <MessageFilled style={{ fontSize: "24px", color: 'white', padding: "5px" }} />
          </Tooltip>
          <Tooltip title="Share">
            <IoMdShareAlt style={{ fontSize: "24px", color: 'white', padding: "5px" }} />
          </Tooltip>
        </Space>
      )}
    </>
  );

  return (
    <div className="read-page-container">
      <div className={`phone-screen ${isMobileView ? 'mobile-view' : ''} ${isFullScreen ? 'full-screen' : ''}`}>
      <p style={{color: 'white'}}>{content.content}</p>
        {/* <div className={`phone-screen-toggle ${isMobileView ? 'hide-in-mobile' : ''}`}>
          <Switch />
        </div>
        <div
          className={`social-buttons ${isFullScreen ? 'hide-in-full-screen' : ''}`}
        >
          <ActionButtons />
        </div> */}
        <div className="expand-button-container" style={{ position: 'absolute', bottom: 10, right: 10 }}>
          {!isMobileView && (
            <Tooltip title="Toggle Full Screen">
              <Button
                className="expand-button"
                onClick={toggleFullScreen}
                shape="circle"
              >
                <ExpandAltOutlined />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadPage;